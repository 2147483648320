<template>

<div class="timeline-head inner">

    <div class="timeline-head-item" key="skip">SKIP TO</div>

    <div class="timeline-head-item" v-for="(group, index) in item.groups" :key="index">{{ group.text }}</div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.timeline-head {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
}

.timeline-head-item {
    color: #45464B;
    font-size: 16px;
    padding: 0px 20px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

</style>
