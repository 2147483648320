<template>

<app-backend-input :label="label" :validation="validation">

    <div class="text" :class="{'is-disabled': isDisabled, 'has-prefix': prefix}">

        <div class="text-prefix" v-if="prefix">{{ prefix }}</div>

        <input class="text-control" :disabled="isDisabled" ref="input" v-bind:value="value" :maxlength="maxlength" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" v-on:keydown="onKeydown" v-if="!canGrow" :type="(isPassword) ? 'password' : 'text'" />

        <textarea class="text-control" v-autogrow :disabled="isDisabled" ref="input" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" v-on:keydown="onKeydown" v-if="canGrow" />

		<div class="text-maxlength" v-tooltip="'Characters remaining'" v-if="maxlength && !isDisabled" :class="{'is-warning': lengthWarning}">{{ lengthRemaining }}</div>

    </div>

</app-backend-input>

</template>

<script>

export default {

    props: ['label', 'value', 'isPassword', 'format', 'canGrow', 'validation', 'placeholder', 'maxlength', 'prefix', 'isDisabled'],

    computed: {

		lengthRemaining: function() {

			return this.maxlength - this.value.length

		},

		lengthWarning: function() {

			return this.lengthRemaining < 10

		}

	},

    methods: {
    
        onKeydown: function(e) {
        
            if (!this.format) return

            if (this.format === 'slug') {
            
                if(this.$_.contains(['1','2','3','4','5','6','7','8','9','0',1,2,3,4,5,6,7,8,9,0,'Backspace','Delete','-','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'], e.key) && !e.ctrlKey) {

                    return
                
                }

            }

            e.stopPropagation()
            e.preventDefault()
        
        }
    
    }

}

</script>

<style scoped>

.text {
    display: flex;
    border: 2px solid #eee;
    border-radius: 4px;
    min-height: 40px;
}

.text-prefix {
    font-size: 16px;
    background-color: #eee;
    font-weight: 300;
    color: #999;
    line-height: 36px;
    padding: 0px 0px 0px 10px;
}

.text-control {
    font-size: 16px;
    color: #343434;
    padding: 0px 10px;
    flex-grow: 1;
}

textarea.text-control {
    padding: 8px 10px;
    line-height: 20px;
	resize: none;
	box-sizing: initial;
}

.text.is-disabled .text-control {
    background-color: #eee;
    font-weight: 300;
}

.text.has-prefix.is-disabled .text-control {
    padding-left: 0px;
}

.text-control::placeholder {
    color: #ccc;
}

.text-maxlength {
    padding-right: 10px;
    line-height: 36px;
    font-size: 12px;
    color: #ccc;
}

.text-maxlength.is-warning {
	color: #c55b5b;
}

</style>
