import _ from 'underscore'

export default {

    copy: function(value) {

        return JSON.parse(JSON.stringify(value))
        
    },

	options: function(values, preserve) {

		var field = 'name'
		var keyField = 'id'
		preserve = preserve || []

		var options = []

		_.each(values, function(value, key) {

			var option = {
				value: (keyField && _.isObject(value)) ? value[keyField] : key,
				text: (field && _.isObject(value)) ? value[field] : value
			}

			_.each(preserve, function(preserveKey) {
			
				option[preserveKey] = value[preserveKey]
			
			})

			options.push(option)

		})

		return options

	},

}