<template>

<i :class="{[finalIcon]: true}" v-tooltip="tooltip" class="icon" v-on:click="$emit('click')" />

</template>

<script>

export default {

    props: ['icon', 'tooltip'],

    data: function() {
    
        return {
            icons: {    

                'loading': 'fa-solid fa-spinner fa-spin-pulse',

                'toolbar.bold': 'fa-solid fa-bold',
                'toolbar.italic': 'fa-solid fa-italic',
                'toolbar.underline': 'fa-solid fa-underline',
                'toolbar.blockquote': 'fa-solid fa-block-quote',
                'toolbar.ulist': 'fa-solid fa-list-ul',
                'toolbar.olist': 'fa-solid fa-list-ol',
                'toolbar.h1': 'fa-solid fa-h1',
                'toolbar.h2': 'fa-solid fa-h2',
                'toolbar.h3': 'fa-solid fa-h3',
                'toolbar.p': 'fa-solid fa-p',
                'toolbar.internal': 'fa-solid fa-link',
                'toolbar.external': 'fa-solid fa-arrow-up-right-from-square',

                'page.dashboard': 'fa-solid fa-dashboard',
                'page.pages': 'fa-solid fa-rectangle-vertical-history',
                'page.menu': 'fa-solid fa-sitemap',
                'page.events': 'fa-solid fa-calendar-days',
                'page.news': 'fa-solid fa-newspaper',
                'page.images': 'fa-solid fa-images',
                'page.files': 'fa-solid fa-files',
                'page.settings': 'fa-solid fa-gear',
                'page.users': 'fa-solid fa-users',
                'page.forms': 'fa-solid fa-input-text',
                'page.logout': 'fa-solid fa-right-from-bracket',

                'action.create': 'fa-solid fa-plus',
                'action.save': 'fa-solid fa-floppy-disk',
                'action.confirm': 'fa-solid fa-check',
                'action.undo': 'fa-solid fa-rotate-left',
                'action.edit': 'fa-solid fa-pencil',
                'action.up': 'fa-solid fa-caret-up',
                'action.down': 'fa-solid fa-caret-down',
                'action.insert': 'fa-solid fa-plus',
                'action.upload': 'fa-solid fa-cloud-arrow-up',
                'action.assets': 'fa-solid fa-grid',
                'action.enlarge': 'fa-solid fa-eye',
                'action.remove': 'fa-solid fa-ban',
                'action.cancel': 'fa-solid fa-times',
                'action.close': 'fa-solid fa-times',
                'action.delete': 'fa-solid fa-trash',
                'action.back': 'fa-solid fa-caret-left',

            }
        }
    
    },

    computed: {
    
        finalIcon: function() {
        
            return this.icons[this.icon]
        
        }
    
    }

}

</script>

<style scoped>

.icon {
    display: inline-block;
}

</style>
