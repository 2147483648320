<template>

<div class="home-feature">

    <div class="hoem-feature-inner inner">

        <div class="home-feature-title"><small>Welcome to</small> Eccleshall <em>Parish</em></div>

        <div class="home-feature-image" v-bgimage="item.image" />

        <div class="home-feature-events">

            <div class="home-feature-events-title">Next services</div>

            <div class="home-feature-events-list">
            
                <div class="home-feature-events-list-item">

                    <div class="home-feature-events-list-item-date">
                    
                        <div class="home-feature-events-list-item-date-day">Day</div>  
                        <div class="home-feature-events-list-item-date-date">00</div>  
                        <div class="home-feature-events-list-item-date-month">Mon</div>  
                    
                    </div>

                    <div class="home-feature-events-list-item-info">
                    
                        <div class="home-feature-events-list-item-info-name">Church Name</div>
                    
                        <div class="home-feature-events-list-item-info-about">
                        
                            <div class="home-feature-events-list-item-info-about-item"><i class="fa-solid fa-clock" />11:30am</div>
                        
                            <div class="home-feature-events-list-item-info-about-item"><i class="fa-solid fa-info" />Said Eucharist</div>
                        
                        </div>
                    
                    </div>

                </div>
            
                <div class="home-feature-events-list-item">

                    <div class="home-feature-events-list-item-date">
                    
                        <div class="home-feature-events-list-item-date-day">Day</div>  
                        <div class="home-feature-events-list-item-date-date">00</div>  
                        <div class="home-feature-events-list-item-date-month">Mon</div>  
                    
                    </div>

                    <div class="home-feature-events-list-item-info">
                    
                        <div class="home-feature-events-list-item-info-name">Church Name</div>
                    
                        <div class="home-feature-events-list-item-info-about">
                        
                            <div class="home-feature-events-list-item-info-about-item"><i class="fa-solid fa-clock" />11:30am</div>
                        
                            <div class="home-feature-events-list-item-info-about-item"><i class="fa-solid fa-info" />Said Eucharist</div>
                        
                        </div>
                    
                    </div>

                </div>
            
            </div>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.home-feature {
    background-color: #00583D;
}

.home-feature-inner {
    display: flex;
    height: 826px;
    flex-direction: column;
    justify-content: space-between;
}

.is-tablet .home-feature,
.is-mobile .home-feature {
    height: auto;
}

.home-feature-title {
    padding: 64px 10px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-size: 140px;
    font-weight: 600;
    z-index: 2;
}

.is-tablet .home-feature-title {
    padding: 32px 10px;
    font-size: 90px;
}

.is-mobile .home-feature-title {
    padding: 20px 10px;
    font-size: 48px;
}

.home-feature-title small {
    display: block;
    font-size: 35px;
    padding-left: 10px;
    font-weight: 400;
}

.is-tablet .home-feature-title small {
    font-size: 28px;
    padding-left: 6px;
}

.is-mobile .home-feature-title small {
    font-size: 16px;
    padding-left: 4px;
}

.home-feature-title em {
    font-family: 'Breathing', sans-serif;
    color: #F0C994;
    margin-top: 16px;
    font-size: 140px;
    text-transform: none;
    font-weight: 400;
    display: block;
}

.is-tablet .home-feature-title em {
    font-size: 90px;
    margin-top: 26px;
}

.is-mobile .home-feature-title em {
    font-size: 45px;
}

.home-feature-image {
    position: absolute;
    right: -10px;
    top: 0px;
    bottom: 0px;
    background-color: #eee;
    background-size: cover;
    background-position: 50% 50%;
    left: 528px;
    z-index: 1;
}

.is-tablet .home-feature-image {
    position: relative;
    right: auto;
    left: auto;
    bottom: auto;
    top: auto;
    width: 100%;
    height: 544px;
}

.is-mobile .home-feature-image {
    position: relative;
    right: auto;
    left: auto;
    bottom: auto;
    top: auto;
    width: 100%;
    height: 280px;
}

.home-feature-events {
    background-color: #fff;
    width: 528px;
}

.is-desktop .home-feature-events:before {
    content: '';
    display: block;
    background-color: #fff;
    position: absolute;
    right: 100%;
    width: 10000px;
    top: 34px;
    bottom: 0px;
}

.is-desktop .home-feature-events:after {
    content: '';
    display: block;
    background-color: #fff;
    position: absolute;
    left: 100%;
    width: 10000px;
    top: 34px;
    bottom: 0px;
}

.is-tablet .home-feature-events,
.is-mobile .home-feature-events {
    width: 100%;
    z-index: 3;
}

.home-feature-events-title {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    background-color: #00583D;
    color: #fff;
    font-size: 14px;
    padding: 0px 0px 20px 10px;
    font-weight: 500;
}

.is-tablet .home-feature-events-title {
    background-color: #fff;
    color: #00583D;
    padding: 20px 10px 0px 10px;
}

.is-mobile .home-feature-events-title {
    background-color: transparent;
    color: #fff;
    padding: 20px 10px 0px 10px;
    top: -50px;
    position: absolute;
}

.home-feature-events-list {
    padding: 20px 0px;
}

.is-tablet .home-feature-events-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.home-feature-events-list-item {
    padding: 20px 10px;
    border-bottom: 1px solid #D5E3D7;
    display: grid;
    grid-template-columns: 74px 1fr;
    grid-gap: 24px;
    align-items: center;
}

.is-tablet .home-feature-events-list-item {
    padding: 0px 10px;
}

.is-tablet .home-feature-events-list-item,
.home-feature-events-list-item:last-child {
    border-bottom: 0px;
}

.home-feature-events-list-item-date {
    border-left: 8px solid #F0C994;
    color: #00583D;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    padding-left: 20px;
}

.home-feature-events-list-item-date-day {
    font-size: 14px;
}

.home-feature-events-list-item-date-date {
    font-size: 28px;
}

.home-feature-events-list-item-date-month {
    font-size: 14px;
}

.home-feature-events-list-item-info-name {
    color: #45464B;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
}

.is-mobile .home-feature-events-list-item-info-name {
    font-size: 13px;
}

.home-feature-events-list-item-info-about {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 10px;
    align-items: center;
}

.home-feature-events-list-item-info-about-item {
    color: #00583D;
    font-size: 14px;
    line-height: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.is-mobile .home-feature-events-list-item-info-about-item {
    font-size: 12px;
}

.home-feature-events-list-item-info-about-item i {
    margin-right: 10px;
}

</style>
