<template>

<router-link :to="route" class="form" v-tooltip="'Click to edit'" v-on:click="$emit('click')">

    <div class="form-info">
    
        <div class="form-info-name">{{ item.name }}</div>

    </div>

    <div class="form-icon">

        <i class="fa-solid fa-chevron-right" />

    </div>

</router-link>

</template>

<script>

export default {

    props: ['item'],

    computed: {
    
        route: function() {
        
            return {
                name: this.$route.meta.editName,
                params: {
                    id: this.item.id
                }
            }
        
        }
    
    }

}

</script>

<style scoped>

.form {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    display: grid;
    background-color: #fff;
    grid-template-columns: 1fr 20px;
    grid-gap: 20px;
    align-items: center;
    border-left: 5px solid #43ac43;
    z-index: 20;
    cursor: pointer;
}

.form:hover {
    background-color: #f7f7f7;
}

.form-info-name {
    font-size: 16px;
    color: #343434;
}

.form-icon {
    font-size: 16px;
    text-align: right;
    color: #666;
}

</style>
