<template>

<div class="block" :class="{'is-active': isActive, 'is-locked': isLocked && isPreview, 'is-preview': isPreview}" v-tooltip="(isPreview) ? 'Click to edit' : false" v-on:click="$emit('click', item.id)">

    <app-frontend-block-head :item="item" v-if="item.type === $constants.blockType.head" />

    <app-frontend-block-foot :item="item" v-if="item.type === $constants.blockType.foot" />

    <app-frontend-block-textimage1 :item="item" v-if="item.type === $constants.blockType.textImage1" />

    <app-frontend-block-textimage2 :item="item" v-if="item.type === $constants.blockType.textImage2" />

    <app-frontend-block-textimage3 :item="item" v-if="item.type === $constants.blockType.textImage3" />

    <app-frontend-block-textimage4 :item="item" v-if="item.type === $constants.blockType.textImage4" />

    <app-frontend-block-buttons :item="item" v-if="item.type === $constants.blockType.buttons" />

    <app-frontend-block-text1 :item="item" v-if="item.type === $constants.blockType.text1" />

    <app-frontend-block-text2 :item="item" v-if="item.type === $constants.blockType.text2" />

    <app-frontend-block-category1 :item="item" v-if="item.type === $constants.blockType.category1" />

    <app-frontend-block-category2 :item="item" v-if="item.type === $constants.blockType.category2" />

    <app-frontend-block-team :item="item" v-if="item.type === $constants.blockType.team" />

    <app-frontend-block-divider1 :item="item" v-if="item.type === $constants.blockType.divider1" />

    <app-frontend-block-divider2 :item="item" v-if="item.type === $constants.blockType.divider2" />

    <app-frontend-block-resources :item="item" v-if="item.type === $constants.blockType.resources" />

    <app-frontend-block-gallery :item="item" v-if="item.type === $constants.blockType.gallery" />

    <app-frontend-block-banner :item="item" v-if="item.type === $constants.blockType.banner" />

    <app-frontend-block-embed :item="item" v-if="item.type === $constants.blockType.embed" />

    <app-frontend-block-news :item="item" v-if="item.type === $constants.blockType.news" />

    <app-frontend-block-locations :item="item" v-if="item.type === $constants.blockType.locations" />

    <app-frontend-block-events :item="item" v-if="item.type === $constants.blockType.events" />

    <app-frontend-block-timeline :item="item" v-if="item.type === $constants.blockType.timeline" />

    <app-frontend-block-timelinehead :item="item" v-if="item.type === $constants.blockType.timelineHead" />

    <app-frontend-block-form :item="item" v-if="item.type === $constants.blockType.form" />

    <app-frontend-block-homefeature :item="item" v-if="item.type === $constants.blockType.homeFeature" />

</div>

</template>

<script>

export default {

    props: ['item', 'isActive'],

    computed: {
    
        isLocked: function() {
        
            return this.item.type === this.$constants.blockType.head || this.item.type === this.$constants.blockType.foot
        
        },
    
        isPreview: function() {
        
            return process.env.VUE_APP_TYPE === 'backend'
        
        }
    
    }

}

</script>

<style scoped>

.block {
    width: 100%;
    overflow: hidden;
}

.block.is-preview {
    cursor: pointer;
}

.block.is-locked {
    pointer-events: none;
}  

.block >>> .inner {
    width: 100%;
    max-width: 1280px;
    margin: 0px auto;
}

.is-desktop .block {
    overflow: visible;
}

.block.is-preview >>> div {
    pointer-events: none;
}

.block:hover {
    /*box-shadow: 0 0 300px 10px #999;
    z-index: 3;*/
}

.block.is-active {
    /*box-shadow: 0 0 30px 10px #48abe0;
    z-index: 2;*/
}

.block >>> .ecc h1 {
    font-weight: 700;
    font-size: 44px;
    line-height: 50px;
    color: #00583D;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-tablet .block >>> .ecc h1 {
    font-size: 36px;
    line-height: 42px;
}

.is-mobile .block >>> .ecc h1 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 15px;
}

.block >>> .ecc h2 {
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;
    color: #00583D;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-tablet .block >>> .ecc h2 {
    font-size: 32px;
    line-height: 36px;
}

.is-mobile .block >>> .ecc h2 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 15px;
}

.block >>> .ecc h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: #00583D;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-tablet .block >>> .ecc h3 {
    font-size: 22px;
    line-height: 30px;
}

.is-mobile .block >>> .ecc h3 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 15px;
}

.block >>> .ecc p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #45464B;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-tablet .block >>> .ecc p {
    font-size: 14px;
    line-height: 24px;
}

.is-mobile .block >>> .ecc p {
    font-size: 13px;
    line-height: 24px;
}

.block >>> .ecc ul,
.block >>> .ecc ol {
    margin-bottom: 30px;
}

.block >>> .ecc li {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #45464B;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .block >>> .ecc li {
    font-size: 14px;
    line-height: 24px;
}

.is-mobile .block >>> .ecc li {
    font-size: 13px;
    line-height: 24px;
}

.block >>> .ecc *:last-child {
    margin-bottom: 0px;
}

</style>
