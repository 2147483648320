<template>

<div class="heading">

    <span>{{ text }}</span>

</div>

</template>

<script>

export default {

    props: ['text']

}

</script>

<style scoped>

.heading {
    border-bottom: 2px solid #eee;
    margin-bottom: 20px;
    height: 22px;
}

.heading span {
    display: inline-block;
    background-color: #fff;
    line-height: 40px;
    font-weight: 500;
    color: #343434;
    padding: 0px 20px 0px 0px;
    font-size: 16px;
}

</style>
