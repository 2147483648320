<template>

<div class="action" :class="{'is-disabled': isDisabled, 'is-slim': isSlim}" :data-theme="theme" v-tooltip="tooltip" v-on:click.stop="onClick">

    <app-backend-icon :icon="icon" />

</div>

</template>

<script>

export default {

    props: ['tooltip', 'icon', 'theme', 'isSlim', 'isDisabled'],

    methods: {
    
        onClick: function() {
        
            this.$emit('click')
        
        }
    
    }

}

</script>

<style scoped>

.action {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #28406d;
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}

.action[data-theme="grey"] {
    background-color: #ccc;
}

.action:hover {
    background-color: #1E3052;
}

.action[data-theme="grey"]:hover {
    background-color: #999;
}

.action[data-theme="red"] {
    background-color: #aa3737;
}

.action[data-theme="red"]:hover {
    background-color: #8d2424;
}

.action.is-disabled {
    pointer-events: none;
    opacity: 0.25;
}

.action.is-slim {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 24px;
}

</style>
