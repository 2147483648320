<template>

<div class="banner" v-bgimage="item.image">

    <div class="banner-inner inner">

        <div class="banner-title">{{ item.title }}</div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.banner {
    background-position: 50% 50%;
    background-size: cover;
    background-color: #eee;
}

.banner-inner {
    height: 458px;
}

.is-tablet .banner-inner {
    height: 320px;
}

.is-mobile .banner-inner {
    height: 200px;
}

.banner-title {
    color: #fff;
    font-size: 64px;
    position: absolute;
    bottom: 50px;
    left: 10px;
    font-family: 'Breathing', sans-serif;
}

.is-tablet .banner-title {
    bottom: 30px;
    font-size: 50px;
}

.is-mobile .banner-title {
    bottom: 20px;
    font-size: 32px;
}

</style>
