<template>

<app-backend-content class="assets" v-if="active" :is-loading="is.loading">

    <app-backend-content-head :title="(isImages) ? 'Images' : 'Files'" :count="list.length" :noun="(isImages) ? 'image' : 'file'">

        <app-backend-content-head-search v-model="search" placeholder="Quick search" />

        <app-backend-content-head-action title="Close" icon="action.close" v-on:click="onCloseClick" />

    </app-backend-content-head>

    <app-backend-content-body :is-empty="!list.length" placeholder="You have not uploaded any assets yet." :is-list="true" class="assets-list">

        <app-backend-list-asset v-for="item in list" :key="item.id" :item="item" :is-selectable="true" v-on:click="onAssetClick(item)" />
        
    </app-backend-content-body>

</app-backend-content>

</template>

<script>

export default {

    data: function() {
    
        return {
            is: {
                loading: true
            },
            search: '',
            rawlist: []
        }

    },
    
    watch: {

        active: function(n) {
        
            if(n) this.load()
        
        },
    
        $path: function() {
        
            this.$store.dispatch('assets/cancel')
        
        }
    
    },

    computed: {

        isImages: function() {
        
            return this.active.type === this.$constants.assetType.image

        },
    
        list: function() {

            if (this.search) {
            
                return this.$_.filter(this.rawlist, function(item) {
                
                    return item.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || item.tags.join(' ').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
                
                }.bind(this))
            
            } else {
            
                return this.rawlist
            
            }
        
        },

        active: function() {
        
            return this.$store.getters['assets/active']
        
        }
    
    },
    
    methods: {

        load: function() {
        
            this.is.loading = true

            this.$api.get((this.isImages) ? 'images' : 'files').then(function(response) {
            
                this.rawlist = response.list

                this.is.loading = false
            
            }.bind(this))
        
        },
    
        onAssetClick: function(item) {

            this.$store.dispatch('assets/resolve', item)

            this.is.loading = true
        
        },
    
        onCloseClick: function() {
        
            this.$store.dispatch('assets/reject')

            this.is.loading = true
        
        }
    
    }

}

</script>

<style scoped>

.assets {
    position: absolute;
    left: 664px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    overflow-y: auto;
    transition: left 100ms linear;
    z-index: 2000;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: inset 10px 0px 15px -3px rgba(0,0,0,0.1);
    padding: 0px 20px;
}

.assets-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-gap: 10px;
}

</style>
