import cookies from 'js-cookie'

export default async function handler(to, from, next) {

	if (to.meta.auth && cookies.get('cookie') === undefined) {

		next({
			name: 'Login',
			query: (to.path != '/' && to.path != '/logout') ? {
				redirect: to.path
			} : {}
		})

	} else if (cookies.get('cookie') !== undefined && to.name === 'Login') {

		next({
			name: 'Pages'
		})

	} 

	next()

}