<template>

<div class="search">

    <input class="search-control" type="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder" />

    <i class="search-icon fa-solid fa-magnifying-glass" />

</div>

</template>

<script>

export default {

    props: ['placeholder', 'value']

}

</script>

<style scoped>

.search {
    border: 2px solid #eee;
    border-radius: 4px;
    height: 40px;
    width: 240px;
    display: flex;
}

.search-control {
    padding: 0px 10px;
    font-size: 14px;
    color: #343434;
    height: 36px;
    width: 100%;
    flex-grow: 1;
}

.search-icon {
    font-size: 16px;
    padding-right: 10px;
    line-height: 36px;
    color: #ccc;
}

</style>
