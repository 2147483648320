<template>

<div class="locations inner">

    <div class="locations-item" v-for="(location, index) in item.locations" :key="index">

        <div class="locations-item-map" v-html="location.embed" />

        <div class="locations-item-title">{{ location.title }}</div>

        <div class="locations-item-text">{{ location.address }}</div>

        <div class="locations-item-subtitle">Parking</div>

        <div class="locations-item-text">{{ location.parking }}</div>

        <div class="locations-item-subtitle">Accessibility</div>

        <div class="locations-item-text">{{ location.accessibility }}</div>

        <div class="locations-item-subtitle">Opening Hours</div>

        <div class="locations-item-text" v-html="$nl2br(location.hours)" />

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.locations {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.is-mobile .locations {
    grid-template-columns: 1fr;
}

.locations-item-map {
    margin-bottom: 40px;
}

.is-tablet .locations-item-map {
    margin-bottom: 30px;
}

.is-mobile .locations-item-map {
    margin-bottom: 20px;
}

.locations-item-map >>> iframe {
    width: 100%!important;
    height: 240px!important;
}

.locations-item-title {
    color: #00583D;
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-tablet .locations-item-title {
    font-size: 28px;
    line-height: 32px;
}

.is-mobile .locations-item-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
}

.locations-item-text {
    color: #45464B;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 40px;
}

.is-tablet .locations-item-text {
    font-size: 14px;
}

.is-mobile .locations-item-text {
    font-size: 13px;
    margin-bottom: 30px;
}

.locations-item-subtitle {
    color: #00583D;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
}

.is-tablet .locations-item-subtitle {
    font-size: 22px;
    line-height: 30px;
}

.is-mobile .locations-item-subtitle {
    font-size: 20px;
    line-height: 26px;
}

</style>
