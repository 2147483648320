<template>

<div class="divider-1 inner">

    <div class="divider-1-subtitle">{{ item.subtitle }}</div>

    <div class="divider-1-title">{{ item.title }}</div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.divider-1 {
    text-align: center;
    padding: 48px 0px 64px 0px;
}

.is-tablet .divider-1 {
    padding: 32px 0px 48px 0px;
}

.is-mobile .divider-1 {
    padding: 20px 0px 30px 0px;
}

.divider-1-subtitle {
    color: #00583D;
    font-size: 24px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-bottom: 48px;
}

.is-mobile .divider-1-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
}

.divider-1-title {
    color: #5AC489;
    font-weight: 400;
    font-size: 94px;
    font-family: 'Breathing', sans-serif;
    background-color: #fff;
    display: inline-block;
    padding: 0px 64px;
}

.is-tablet .divider-1-title {
    font-size: 64px;
    padding: 0px 32px;
}

.is-mobile .divider-1-title {
    font-size: 32px;
    padding: 0px 20px;
}

.divider-1:before {
    position: absolute;
    height: 2px;
    content: '';
    display: block;
    background-color: #F0C994;
    left: 0px;
    right: 0px;
    top: 168px;
}

.is-tablet .divider-1:before {
    top: 130px;
}

.is-mobile .divider-1:before {
    top: 66px;
}

</style>
