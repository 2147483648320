<template>

<app-backend-input :label="label">

    <slot></slot>

</app-backend-input>

</template>

<script>

export default {

    props: ['label']

}

</script>

<style scoped>

</style>
