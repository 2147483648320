<template>

<div class="text-image-2 inner">

    <div class="text-image-2-image">

        <div class="text-image-2-image-image" v-bgimage="item.image" />

    </div>

    <div class="text-image-2-text ecc" v-html="item.text" />   

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.text-image-2 {
    display: grid;
    grid-template-columns: 580px 1fr;
    grid-gap: 70px;
    padding: 0px 10px;
}

.is-tablet .text-image-2 {
    grid-template-columns: 272px 1fr;
    grid-gap: 30px;
}

.is-mobile .text-image-2 {
    grid-template-columns: 1fr;
    grid-gap: 30px;
}

.text-image-2-image-image {
    width: 580px;
    height: 455px;
    background-size: cover;
    background-position: 50% 50%;
}

.is-tablet .text-image-2-image-image {
    width: 272px;
    height: 240px;
}

.is-mobile .text-image-2-image-image {
    width: 100%;
    height: 240px;
}

.text-image-2-text {
    padding-top: 120px;
}

.is-tablet .text-image-2-text {
    padding-top: 0px;
}

.is-mobile .text-image-2-text {
    padding-top: 0px;
}

</style>
