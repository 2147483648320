<template>

<app-backend-content class="enlarge" v-if="active">

    <app-backend-content-head :title="title">

        <app-backend-content-head-action title="Close" icon="action.close" v-on:click="onCloseClick" />

    </app-backend-content-head>

    <app-backend-content-body class="enlarge-body">

        <div class="enlarge-image" v-bgimage="active" v-if="isImage" />

        <iframe class="enlarge-iframe" :src="active" height="100%" width="100%" v-if="!isImage"></iframe>

    </app-backend-content-body>

</app-backend-content>

</template>

<script>

export default {
    
    watch: {

        $path: function() {
        
            this.$store.dispatch('enlarge/cancel')
        
        }
    
    },

    computed: {

        title: function() {
        
            return (this.isImage) ? 'View image' : 'View file'
        
        },

        isImage: function() {

            return this.active.indexOf('jpg') > 0 || this.active.indexOf('png') > 0 || this.active.indexOf('jpeg') > 0

        },
    
        active: function() {
        
            return this.$store.getters['enlarge/active']
        
        }
    
    },
    
    methods: {
    
        onCloseClick: function() {
        
            this.$store.dispatch('enlarge/reject')

        }
    
    }

}

</script>

<style scoped>

.enlarge {
    position: absolute;
    left: 664px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    overflow-y: auto;
    transition: left 100ms linear;
    z-index: 2000;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: inset 10px 0px 15px -3px rgba(0,0,0,0.1);
    padding: 0px 20px;
}

.enlarge-body {
    height: calc(100% - 60px);
    padding: 20px;
}

.enlarge-image {
    border-radius: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
}

</style>
