export default {

	namespaced: true,

	state: {
		fullscreen: false,
		blocks: [],
		focus: ''
	},

	getters: {

		fullscreen: function(state) {

			return state.fullscreen

		},

		blocks: function(state) {
		
			return state.blocks
		
		},

		focus: function(state) {
		
			return state.focus
		
		}

	},

	mutations: {

        fullscreen: function(state, value) {
        
            state.fullscreen = value
        
        },

        blocks: function(state, value) {
        
            state.blocks = value
        
        },

        focus: function(state, value) {
        
            state.focus = value
        
        }

	}

}
