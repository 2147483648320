<template>

<div class="resources inner">

    <div class="resources-title">{{ item.title }}</div>

    <div class="resources-files">

        <div class="resources-files-item" v-for="(file, index) in item.files" :key="index">

            <a  class="resources-files-item-image" :href="file.file" v-bgimage="file.image" />

            <div class="resources-files-item-name">{{ file.name }}</div>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.resources-title {
    color: #00583D;
    font-size: 35px;
    line-height: 40px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-bottom: 48px;
}

.is-tablet .resources-title {
    font-size: 28px;
    line-height: 32px;
}

.is-mobile .resources-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: center;
}

.resources-files {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.is-tablet .resources-files {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.is-mobile .resources-files {
    grid-template-columns: 1fr 1fr;
}

.resources-files-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resources-files-item-image {
    width: 174px;
    height: 220px;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 40px;
    border: 1px solid #707070;
}

.is-tablet .resources-files-item-image {
    width: 140px;
    height: 180px;
    margin-bottom: 20px;
}

.is-mobile .resources-files-item-image {
    width: 100px;
    height: 126px;
    margin-bottom: 15px;
}

.resources-files-item-name {
    color: #00583D;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    line-height: 34px;
}

.is-tablet .resources-files-item-name {
    font-size: 20px;
    line-height: 24px;
}

.is-mobile .resources-files-item-name {
    font-size: 16px;
    line-height: 24px;
}

</style>
