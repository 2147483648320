<template>

<div class="category-1" :data-background="item.background" v-if="is.loading || list.length">

    <div class="inner">

        <div class="category-1-title" v-if="item.title && !is.loading">{{ item.title }}</div>

        <app-backend-icon class="events-loading" icon="loading" v-if="is.loading" />

        <div class="category-1-links" v-if="!is.loading">

            <a :href="link.url" class="category-1-links-item" v-for="(link, index) in list" :key="index">

                <div class="category-1-links-item-image" v-bgimage="link.image" />

                <div class="category-1-links-item-title">{{ link.title }}</div>

            </a>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item'],

    data: function() {
    
        return {
            is: {
                loading: false
            },
            rawlist: []
        }

    },

    created: function() {
    
        if (this.item.source !== 3) {
        
            this.load()

        }
    
    },

    computed: {
    
        list: function() {
        
            var list = (this.item.source === 3) ? this.item.categories : this.rawlist
            
            return list
        
        }

    },

    methods: {
    
        load: function() {

            this.is.loading = true
        
            this.$api.get('../frontend/categories', {
                source: this.item.source,
                id: this.item.id
            }).then(function(response) {
            
                this.$vueset(this, 'rawlist', response.list)    

                this.is.loading = false        
            
            }.bind(this))
        
        }
    
    }

}

</script>

<style scoped>

.category-1 {
    padding: 100px 10px;
}

.category-1[data-background="1"] {
    background-color: #FBF1E4;
}

.is-tablet .category-1 {
    padding: 64px 10px;
}

.is-mobile .category-1 {
    padding: 20px 10px;
}

.category-1-title {
    text-transform: uppercase;
    color: #00583D;
    font-size: 24px;
    font-weight: 34px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-mobile .category-1-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
}

.category-1-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.is-mobile .category-1-links {
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.category-1-links-item-image {
    background-position: 50% 50%;
    background-size: cover;
    height: 180px;
    border-bottom: 7px solid #4BA574;
    background-color: #eee;
}

.is-tablet .category-1-links-item-image {
    height: 126px;
    border-bottom-width: 5px;
}

.is-mobile .category-1-links-item-image {
    height: 126px;
    border-bottom-width: 5px;
}

.category-1-links-item-title {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #d5e3d7;
    color: #00583D;
    font-size: 24px;
    line-height: 34px;
    height: 120px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .category-1-links-item-title {
    font-size: 20px;
    line-height: 24px;
}

.is-mobile .category-1-links-item-title {
    font-size: 16px;
    height: 100px;
    line-height: 24px;
}

</style>
