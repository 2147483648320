<template>

<component :is="component" :to="route" class="asset" v-tooltip="(isSelectable) ? 'Click to select' : 'Click to view'" v-on:click="$emit('click')">

    <div class="asset-preview" v-bgimage="(isImage) ? item.filename : ''" :class="{'is-file': !isImage}">

        <iframe class="asset-iframe" :src="item.filename" v-if="!isImage" />

        <div class="asset-uses" v-if="item.uses">{{ item.uses }}</div>

    </div>

    <div class="asset-tags">

        <div class="asset-tags-item" v-for="(tag, index) in item.tags" :key="index">{{ tag }}</div>

    </div>

</component>

</template>

<script>

export default {

    props: ['item', 'isSelectable'],

    computed: {

        component: function() {
        
            return (this.isSelectable) ? 'div' : 'router-link'

        },

        isImage: function() {
        
            return this.item.type === this.$constants.assetType.image

        },

        route: function() {
        
            return {
                name: this.$route.meta.editName,
                params: {
                    id: this.item.id
                }
            }
        
        }
    
    }

}

</script>

<style scoped>

.asset {
    display: block;
}

.asset-preview {
    height: 160px;
    background-color: #eee;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 4px;
    border: 5px solid #eee;
    cursor: pointer;
}

.asset-preview:hover {
    background-color: #ccc;
    border-color: #ccc;
}

.asset-iframe {
    height: 150px;
    width: 100%;
    border: 0px;
    pointer-events: none;
}

.asset-uses {
    position: absolute;
    background-color: #28406d;
    color: #fff;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    padding: 0px 4px;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    right: -10px;
    top: -10px;
}

.asset-tags {
    display: flex;
    margin-top: 4px;
}

.asset-tags-item {
    border-radius: 4px;
	background-color: #28406d;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    margin-right: 4px;
}

</style>
