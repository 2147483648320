import { render, staticRenderFns } from "./Checkbox.vue?vue&type=template&id=49cb9271&scoped=true"
import script from "./Checkbox.vue?vue&type=script&lang=js"
export * from "./Checkbox.vue?vue&type=script&lang=js"
import style0 from "./Checkbox.vue?vue&type=style&index=0&id=49cb9271&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49cb9271",
  null
  
)

export default component.exports