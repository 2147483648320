<template>

<div class="content" :class="{'is-inline': isInline}">

    <app-backend-icon icon="loading" class="content-loading" v-if="isLoading" />

    <div class="content-inner" v-if="!isLoading">

        <slot></slot>

    </div>

</div>

</template>

<script>

export default {

    props: ['isLoading', 'isInline']

}

</script>

<style scoped>

.content {
    min-height: 100vh;
}

.content.is-inline {
    position: fixed;
    left: 64px;
    top: 0px;
    width: 600px;
    bottom: 0px;
    background-color: #fff;    
}

.content-inner {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.content-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 32px;
    color: #343434;
    transform: translate(-50%, -50%);
}

</style>
