<template>

<div class="block" :class="{'is-first': isFirst}">

    <app-backend-icon class="block-insert is-before" tooltip="Add block" icon="action.insert" v-on:click="onInsertBeforeClick" v-if="isFirst" />

    <div class="block-image" :data-type="item.type" />

    <div class="block-info">
    
        <div class="block-info-name">{{ $constants.blockTypeName[item.type] }}</div>

    </div>

    <div class="block-actions">

        <app-backend-action class="block-actions-item" :is-disabled="isFirst" theme="grey" icon="action.up" tooltip="Move up" v-on:click="onUpClick" />
        
        <app-backend-action class="block-actions-item" :is-disabled="isLast" theme="grey" icon="action.down" tooltip="Move down" v-on:click="onDownClick" />
        
        <app-backend-action class="block-actions-item" icon="action.edit" tooltip="Edit block" v-on:click="onEditClick" />
        
        <app-backend-action class="block-actions-item" theme="red" icon="action.cancel" tooltip="Delete block" v-on:click="onDeleteClick" />

    </div>

    <app-backend-icon class="block-insert is-after" tooltip="Add block" icon="action.insert" v-on:click="onInsertAfterClick" />

</div>

</template>

<script>

export default {

    props: ['item', 'index', 'isFirst', 'isLast'],

    methods: {
    
        onInsertBeforeClick: function() {

            this.$emit('insert', 0)
        
        },

        onInsertAfterClick: function() {
        
            this.$emit('insert', this.index + 1)
        
        },
        
        onUpClick: function() {
        
            this.$emit('move', {
                index: this.index,
                direction: 'up'
            })
        
        },

        onDownClick: function() {
        
            this.$emit('move', {
                index: this.index,
                direction: 'down'
            })

        },
        
        onEditClick: function() {
        
            this.$emit('edit', this.index)
        
        },

        onDeleteClick: function() {
        
            this.$emit('delete', this.index)
        
        }
    
    }

}

</script>

<style scoped>

.block {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 80px auto auto;
    align-items: center;
}

.block:hover {
    background-color: #f7f7f7;
}

.block.is-first {
    border-top: 1px solid #eee;
}

.block-image {
    border-radius: 4px;
    background-color: #eee;
    height: 64px;
    border: 10px solid #eee;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.block-image[data-type="1"] { background-image: url('@/assets/text-image-1.png'); }
.block-image[data-type="2"] { background-image: url('@/assets/text-image-2.png'); }
.block-image[data-type="3"] { background-image: url('@/assets/text-image-3.png'); }
.block-image[data-type="4"] { background-image: url('@/assets/buttons.png'); }
.block-image[data-type="5"] { background-image: url('@/assets/text-1.png'); }
.block-image[data-type="6"] { background-image: url('@/assets/text-2.png'); }
.block-image[data-type="7"] { background-image: url('@/assets/category-1.png'); }
.block-image[data-type="8"] { background-image: url('@/assets/category-2.png'); }
.block-image[data-type="9"] { background-image: url('@/assets/team.png'); }
.block-image[data-type="10"] { background-image: url('@/assets/divider-1.png'); }
.block-image[data-type="11"] { background-image: url('@/assets/divider-2.png'); }
.block-image[data-type="12"] { background-image: url('@/assets/form.png'); }
.block-image[data-type="13"] { background-image: url('@/assets/resources.png'); }
.block-image[data-type="14"] { background-image: url('@/assets/gallery.png'); }
.block-image[data-type="15"] { background-image: url('@/assets/banner.png'); }
.block-image[data-type="16"] { background-image: url('@/assets/embed.png'); }
.block-image[data-type="17"] { background-image: url('@/assets/news.png'); }
.block-image[data-type="18"] { background-image: url('@/assets/timeline-group.png'); }
.block-image[data-type="19"] { background-image: url('@/assets/locations.png'); }
.block-image[data-type="20"] { background-image: url('@/assets/events.png'); }
.block-image[data-type="21"] { background-image: url('@/assets/text-image-4.png'); }
.block-image[data-type="22"] { background-image: url('@/assets/home-feature.png'); }
.block-image[data-type="23"] { background-image: url('@/assets/timeline-head.png'); }
.block-image[data-type="24"] { background-image: url('@/assets/calendar.png'); }

.block-info-name {
    font-size: 16px;
    color: #343434;
}

.block-actions {
    display: flex;
    justify-content: flex-end;
}

.block-actions-item {
    margin-left: 5px;
    cursor: pointer;
}

.block-insert {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 38px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    font-size: 16px;
    border: 2px solid #eee;
    color: #343434;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    z-index: 2;
}

.block-insert.is-before {
    top: 0px;
}

.block-insert.is-after {
    top: 100%;
}

.block-insert:hover {
    background-color: #28406d;
    border-color: #28406d;
    color: #fff;
    cursor: pointer;
}

</style>
