import Vue from "vue"
import nl2br from 'nl2br'

export default {

	computed: {

		$path: function() {

			return this.$route.path

		},

		$loggedin: function() {

			return this.$store.getters['loggedin']

		}

	},

	methods: {
	
		$nl2br: function(value) {
		
			return nl2br(value)
		
		},

		$toastSuccess: function(text) {

			Vue.$toast.open({
				type: 'success',
				message: text,
				position: 'top'
			})

		},

		$toastError: function(text) {

			Vue.$toast.open({
				type: 'error',
				message: text,
				position: 'top'
			})

		},

		$vueset: function(object, name, value) {
		
			Vue.set(object, name, value)

		},

		$vuedel: function(object, name) {
		
			Vue.delete(object, name)

		}

	}

}