<template>

<div class="text-1 inner">

    <div class="text-1-text ecc" v-html="item.text" />

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.text-1 {
    padding: 0px 10px;
}

</style>
