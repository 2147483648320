<template>

<div class="preview">

    <div class="preview-toolbar">

        <div class="preview-toolbar-item" :class="{'is-active': type === 'fullscreen'}" v-tooltip="'Fullscreen'" v-on:click="onFullscreenClick"><i class="fa-solid fa-maximize" /></div>
        
        <div class="preview-toolbar-item" :class="{'is-active': type === 'browser'}" v-tooltip="'Browser'" v-on:click="onBrowserClick"><i class="fa-solid fa-browser" /></div>
        
        <div class="preview-toolbar-item" :class="{'is-active': type === 'mobile'}" v-tooltip="'Mobile'"  v-on:click="onMobileClick"><i class="fa-solid fa-mobile" /></div>

    </div>

    <div class="preview-device" :class="{'is-locked': !isViewingPage, 'is-desktop': isDesktop, 'is-tablet': isTablet, 'is-mobile': isMobile}" :data-type="type">

        <app-backend-icon icon="loading" class="preview-device-loading" v-if="is.loading" />

        <template v-if="!is.loading">

            <app-frontend-block :item="{ type: $constants.blockType.head, site: site }" key="head" />

            <app-frontend-block :ref="block.id" :item="block" v-for="block in blocks" :key="block.id" :is-active="block.id === focus" v-on:click="onEditClick" />

            <div class="preview-device-empty" key="empty" v-if="!blocks.length">No blocks added to page yet.</div>

            <app-frontend-block :item="{ type: $constants.blockType.foot, site: site  }" key="foot" />

        </template>

    </div>

</div>

</template>

<script>

export default {

    data: function() {
    
        return {
            is: {
                loading: false
            },
            site: {},
            type: 'mobile',

        }
    
    },

    created: function() {
    
        this.load()

    },

    watch: {
    
        focus: function(n) {
        
            if(n) this.$refs[n][0].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })

        },

        isViewingPage: function(n) {
        
            if (!n) this.load()
        
        }

    },

    computed: {

        isViewingPage: function() {
        
            return this.$route.meta.api === 'page'

        },

        isDesktop: function() {
        
            return this.type === 'fullscreen' || (this.type === 'browser' && this.window.width >= 2000)
        
        },

        isTablet: function() {
        
            return this.type === 'browser' && this.window.width < 2000
        
        },

        isMobile: function() {
        
            return this.type === 'mobile'
        
        },
    
        blocks: function() {
        
            return this.$store.getters['preview/blocks']
        
        },
    
        focus: function() {
        
            return this.$store.getters['preview/focus']
        
        }

    },

    methods: {

        load: function() {
        
            this.is.loading = true
        
            this.$api.get('frontend/site').then(function(response) {
            
                this.$vueset(this, 'site', response)
            
            }.bind(this))
        
            this.$api.get('home').then(function(response) {
            
                this.$store.commit('preview/blocks', response.model.blocks) 
                
                this.is.loading = false

            }.bind(this))
        
        },
    
        onFullscreenClick: function() {

            this.type = 'fullscreen'

            this.$store.commit('preview/fullscreen', true);
        
        },

        onBrowserClick: function() {

            this.type = 'browser'

            this.$store.commit('preview/fullscreen', false);
        
        },

        onMobileClick: function() {
        
            this.type = 'mobile'

            this.$store.commit('preview/fullscreen', false);
        
        },

        onEditClick: function(id) {

            if (this.type === 'fullscreen') {
            
                this.type = 'browser'

                this.$store.commit('preview/fullscreen', false);
            
            }

            this.$pubsub.$emit('block.edit', id)
        
        }
    
    }

}

</script>

<style scoped>

.preview {
    position: absolute;
    left: 664px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    overflow-y: auto;
    transition: left 100ms linear;
}

.is-fullscreen .preview {
    left: 0px;
}

.preview-toolbar {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    padding: 0px 10px;
    background-color: #fff;
    border-radius: 4px;
    grid-template-columns: 40px 40px 40px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .25);
    z-index: 2;
}

.preview-toolbar-item {
    font-size: 20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    cursor: pointer;
    color: #999;
    text-align: center;
}

.preview-toolbar-item:hover {
    color: #343434;
}

.preview-toolbar-item.is-active {
    color: #343434;
}

.preview-device {
    position: absolute;
    box-sizing: initial;
    background-color: #fff;
    overflow: auto;
    z-index: 1;
    border-radius: 16px;
    border: 6px solid #000;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .25);
    z-index: 1;
    padding: 0px 0px;
}

.preview-device.is-locked >>> .block {
    pointer-events: none;
}

.preview-device[data-type="mobile"] {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 375px;
    max-height: 667px;
    height: calc(100% - 160px);
}

.preview-device[data-type="browser"] {
    left: 20px;
    top: 20px;
    right: 20px;
    bottom: 85px;
}

.preview-device[data-type="fullscreen"] {
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border: 0px;
    border-radius: 0px;
}

.preview-device-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 24px;
    color: #28406d;
    width: 24px;
    margin-left: -12px;
    height: 24px;
}

.preview-device-empty {
    font-size: 24px;
    color: #ccc;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    padding: 40px;
    min-height: 200px;
    display: flex;
    align-items: center;
}

</style>
