<template>

<div class="head">

    <div class="head-back" v-if="hasBack">

        <app-backend-action icon="action.back" :is-slim="true" :tooltip="hasBack" v-on:click="$emit('back')" />

    </div>

    <div class="head-info">

        <div class="head-info-title">{{ title }}</div>

        <div class="head-info-subtitle" v-if="noun">
        
            {{ count }} {{ nounFinal }}
            
        </div>

        <div class="head-info-subtitle" v-if="subtitle">
        
            {{ subtitle }}
            
        </div>
        
    </div>

    <div class="head-actions">

        <slot></slot>

    </div>

</div>

</template>

<script>

export default {

    props: ['title', 'subtitle', 'count', 'noun', 'hasBack'],

    computed: {
    
        nounFinal: function() {
        
            return (this.count === 1) ? this.noun : this.noun + 's'
        
        }
    
    }

}

</script>

<style scoped>

.head {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    height: 60px;
}

.head-back {
    margin-right: 10px;
}

.head-info {
    flex-grow: 1;
}

.head-info-title {
    font-size: 20px;
    font-weight: 400;
    color: #343434;
}

.head-info-subtitle {
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
    color: #666;
}

.head-actions {
    display: flex;
}

</style>
