<template>

<div class="checkbox">

    <div class="checkbox-control"></div>

    <div class="checkbox-text">{{ input.text }}</div>

</div>

</template>

<script>

export default {

    props: ['input']

}

</script>

<style scoped>

.checkbox {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
}

.is-mobile .checkbox {
    margin-bottom: 10px;
}

.checkbox-control {
    border: 1px solid #00573C;
    border-radius: 8px;
    width: 32px;
    height: 32px;
}

.checkbox-text {
    color: #45464B;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .checkbox-text {
    font-size: 14px;
    line-height: 24px;
}

.is-mobile .checkbox-text {
    font-size: 13px;
    line-height: 24px;
}

</style>
