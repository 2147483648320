<template>

<div class="sidebar">

    <com-page text="Pages" route="Pages" icon="page.pages" />

    <div class="sidebar-group">
    
        <com-page text="Menu" route="Menu" icon="page.menu" />
        <com-page text="Events" route="Events" icon="page.events" />
        <com-page text="News" route="News" icon="page.news" />

    </div>

    <com-page text="Forms" route="Forms" icon="page.forms" />
    <com-page text="Images" route="Images" icon="page.images" />
    <com-page text="Files" route="Files" icon="page.files" />

    <div class="sidebar-divide" />

    <com-page text="Users" route="Users" icon="page.users" />
    <com-page text="Settings" route="Settings" icon="page.settings" />
    <com-page text="Logout" route="Logout" icon="page.logout" />

</div>

</template>

<script>

import comPage from './sidebar/Page'

export default {

    components: {
        'com-page': comPage
    }

}

</script>

<style scoped>

.sidebar {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 64px;
    background-color: #28406d;
    display: flex;
    flex-direction: column;
}

.sidebar-group {
}

.sidebar-divide {
    flex-grow: 1;
}

</style>
