<template>

<div class="textarea">

    <textarea :placeholder="input.placeholder" />

</div>

</template>

<script>

export default {

    props: ['input']

}

</script>

<style scoped>

.textarea {
    border-radius: 8px;
    border: 1px solid #00573C;
    margin-bottom: 20px;
}

.is-mobile .textarea {
    margin-bottom: 10px;
}

.textarea textarea {
    width: 100%;
    padding: 20px;
    color: #343434;
    font-size: 16px;
    line-height: 20px;
    height: 230px;
}

.textarea textarea::placeholder {
    text-transform: uppercase;
    font-size: 14px;
    color: #00583D;
    letter-spacing: 1.4px;
}

</style>
