<template>

<app-backend-content class="link" v-if="active">

    <app-backend-content-head :title="(isInternal) ? 'Internal Link' : 'External Link'">

        <app-backend-content-head-search v-model="search" placeholder="Quick search" v-if="isInternal" />

        <app-backend-content-head-action title="Close" icon="action.close" v-on:click="onCloseClick" />

    </app-backend-content-head>

    <app-backend-content-body :is-empty="!list.length" placeholder="No pages found." :is-list="true" v-if="isInternal">

        <app-backend-list-page v-for="item in list" :key="item.id" :item="item" :is-selectable="true" v-on:click="onPageClick(item)" />
        
    </app-backend-content-body>

    <app-backend-content-body v-if="isExternal" class="link-external">

        <app-backend-input-text label="Text" placeholder="Enter link text" v-model="model.text" :validation="$v.model.text" />

        <app-backend-input-text label="URL" placeholder="Enter full URL" v-model="model.href" :validation="$v.model.href" />

            {{ $v }}

        <div class="link-buttons">

            <app-backend-button text="Confirm" :is-disabled="!isValid" v-on:click="onConfirmClick" />

            <app-backend-button text="Cancel" theme="plainblue" v-on:click="onCloseClick" />

        </div>
        
    </app-backend-content-body>

</app-backend-content>

</template>

<script>

import mixRecord from '@/mixins/record'

export default {

    mixins: [mixRecord],
    
    data: function() {
    
        return {
            inline: true,
            is: {
                loading: true
            },
            template: {
                text: '',
                href: ''
            },
            search: '',
            rawlist: []
        }
    
    },

    watch: {

        active: function(n) {
        
            if(n && this.isInternal) this.loadPages()

            if(n && this.isExternal) this.prepare()
        
        },

        $path: function() {
        
            this.$store.dispatch('link/cancel')
        
        }
    
    },

    computed: {

        validation: function() {
            
            var validations = {
                text: {
                    required: true
                },
                href: {
                    required: true,
                    url: true
                }
            }

            return validations
            
        },

        list: function() {

            if (this.search) {
            
                return this.$_.filter(this.rawlist, function(item) {
                
                    return item.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 
                
                }.bind(this))
            
            } else {
            
                return this.rawlist
            
            }
        
        },

        isInternal: function() {
        
            return this.type === 'internal'
        
        },

        isExternal: function() {
        
            return this.type === 'external'

        },

        type: function() {
        
            return this.active.type
        
        },
    
        active: function() {
        
            return this.$store.getters['link/active']
        
        }
    
    },
    
    methods: {

        prepare: function() {
        
            this.is.loading = false

            this.model.text = this.active.text 
            this.model.href = this.active.href

        },

        loadPages: function() {
        
            this.is.loading = true

            this.$api.get('pages').then(function(response) {
            
                this.rawlist = response.list

                this.is.loading = false
            
            }.bind(this))
        
        },
    
        onPageClick: function(page) {

            this.$store.dispatch('link/resolve', {
                url: page.url
            })
        
        },

        onConfirmClick: function() {

            this.$store.dispatch('link/resolve', {
                url: this.model.href,
                text: this.model.text
            })
        
        },
    
        onCloseClick: function() {
        
            this.$store.dispatch('link/reject')
        
        }
    
    }

}

</script>

<style scoped>

.link {
    position: absolute;
    left: 664px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    overflow-y: auto;
    transition: left 100ms linear;
    z-index: 2000;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: inset 10px 0px 15px -3px rgba(0,0,0,0.1);
    padding: 0px 20px;
}

.link-external {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 60px);
}

.link-external >>> .input {
    max-width: 600px;
    width: 100%;
}

.link-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 400px;
    grid-gap: 10px;
    margin-top: 20px;
}

</style>
