<template>

<div class="confirm" :data-theme="active.theme" v-if="active">

    <app-backend-icon class="confirm-icon" :icon="active.icon" v-if="active.icon" />

    <div class="confirm-title">{{ active.title }}</div>   

    <div class="confirm-text">{{ active.text }}</div>    

    <div class="confirm-buttons">

        <app-backend-button text="Confirm" :is-loading="is.loading" theme="invertred" v-on:click="onConfirmClick" />

        <app-backend-button text="Nevermind" :is-disabled="is.loading" theme="invertwhite" v-on:click="onCancelClick" />

    </div>

</div>

</template>

<script>

export default {

    data: function() {
    
        return {
            is: {
                loading: false
            }
        }
    
    },

    watch: {

        active: function() {
        
            this.is.loading = false

        },
    
        $path: function() {
        
            
            this.$store.dispatch('confirm/cancel')
        
        }
    
    },

    computed: {
    
        active: function() {
        
            return this.$store.getters['confirm/active']
        
        }
    
    },
    
    methods: {
    
        onConfirmClick: function() {

            this.is.loading = true
        
            this.$store.dispatch('confirm/resolve')
        
        },
    
        onCancelClick: function() {
        
            this.$store.dispatch('confirm/reject')
        
        }
    
    }

}

</script>

<style scoped>

.confirm {
    position: absolute;
    left: 664px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    overflow-y: auto;
    transition: left 100ms linear;
    z-index: 2000;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.confirm[data-theme="red"] {
    background-color: #aa3737;
    color: #fff;
}

.confirm-icon {
    width: 128px;
    height: 128px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 128px;
    font-size: 48px;
    margin-bottom: 20px;
}

.confirm[data-theme="red"] .confirm-icon {
    color: #aa3737;
}

.confirm-title {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
}

.confirm-text {
    font-size: 20px;
    line-height: 24px;
    max-width: 600px;
    text-align: center;
    padding: 0px 32px;
    font-weight: 300;
}

.confirm-buttons {
    margin-top: 40px;
    width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

</style>
