<template>

<router-link :to="{ name: route }" v-tooltip.right="text" :data-border="border" class="page" :class="{'is-active': route === $route.meta.section}">

    <app-backend-icon :icon="icon" />

</router-link>

</template>

<script>

export default {

    props: ['text', 'route', 'icon', 'border']

}

</script>

<style scoped>

.page { 
    display: block;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #fff;
    font-size: 24px;
}

.page:hover {
    background-color: rgba(0, 0, 0, 0.25);
}

.page.is-active {
    background-color: #fff;
    color: #28406d;
}

.page[data-border="top"] {
    margin-top: 1px;
}

.page[data-border="top"]:before {
    position: absolute;
    content: '';
    display: block;
    left: 0px;
    top: -1px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
}

</style>
