<template>

<div class="button" :class="{'is-loading': isLoading, 'is-disabled': isDisabled}" :data-theme="theme" v-on:click="$emit('click')">

    <div class="button-text">{{ text }}</div>

    <app-backend-icon class="button-loading" icon="loading" v-if="isLoading" />

</div>

</template>

<script>

export default {

    props: ['text', 'theme', 'isDisabled', 'isLoading']

}

</script>

<style scoped>

.button {
    border-radius: 4px;
    background-color: #28406d;
    color: #fff;
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.button.is-disabled {
    pointer-events: none;
    opacity: 0.25;
}

.button.is-loading {
    pointer-events: none;
}

.button.is-loading .button-text {
    opacity: 0;
}

.button:hover {
    background-color: #1E3052;
}

.button[data-theme="plainblue"] {
    color: #28406d;
    background-color: #fff;
}

.button[data-theme="plainblue"]:hover:not(.is-loading) {
    background-color: rgba(0, 0, 0, 0.1);
}

.button[data-theme="invertred"] {
    color: #aa3737;
    background-color: #fff;
}

.button[data-theme="invertred"]:hover:not(.is-loading) {
    color: #fff;
    background-color: #832323;
}

.button[data-theme="invertwhite"] {
    color: #fff;
    background-color: transparent;
}

.button[data-theme="invertwhite"]:hover {
    background-color: rgba(0, 0, 0, 0.1);
    background-color: transparent;
}

.button-loading {
    position: absolute;
    left: 50%;
    top: 0px;
    line-height: 40px;
    margin-left: -20px;
    width: 40px;
    font-size: 20px;
}

.button[data-theme="invertred"] .button-loading {
    color: #aa3737;
}

</style>
