<template>

<div class="news inner" v-if="is.loading || list.length">

    <app-backend-icon class="news-loading" icon="loading" v-if="is.loading" />

    <div class="news-links" v-if="!is.loading">

        <a :href="link.url" class="news-links-item" v-for="(link, index) in list" :key="index">

            <div class="news-links-item-image" v-bgimage="link.image" />

            <div class="news-links-item-info">

                <div class="news-links-item-date">{{ link.date  | localDate('DD MMM') }}</div>

                <div class="news-links-item-title">{{ link.name }}</div>

            </div>

        </a>

    </div>

</div>

</template>

<script>

export default {

    props: ['item'],

    data: function() {
    
        return {
            is: {
                loading: true
            },
            rawlist: []
        }

    },

    created: function() {
    
        this.load()
    
    },

    computed: {
    
        list: function() {
        
            var list = []

            if (this.item.limit === 2 && this.rawlist.length > 3) {

                list = this.rawlist.slice(0, 3)
            
            } else {
            
                list = this.rawlist
            
            }

            return list
        
        }

    },

    methods: {
    
        load: function() {
        
            this.$api.get('../frontend/news', {
                limit: this.item.limit
            }).then(function(response) {
            
                this.$vueset(this, 'rawlist', response.list)    

                this.is.loading = false        
            
            }.bind(this))
        
        }
    
    }

}

</script>

<style scoped>

.news-loading {
    font-size: 32px;
    color: #00583D;
    margin: 0px auto;
    display: block;
    width: 32px;
}

.news-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.is-mobile .news-links {
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.news-links-item-image {
    background-position: 50% 50%;
    background-size: cover;
    height: 180px;
    border-bottom: 7px solid #4BA574;
}

.is-tablet .news-links-item-image {
    height: 126px;
    border-bottom-width: 5px;
}

.is-mobile .news-links-item-image {
    height: 126px;
    border-bottom-width: 5px;
}

.news-links-item-info {
    background-color: #fff;
    padding: 20px;
}

.news-links-item-date {
    color: #45464B;
    font-size: 12px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.news-links-item-title {
    color: #00583D;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .news-links-item-title {
    font-size: 20px;
    line-height: 24px;
}

.is-mobile .news-links-item-title {
    font-size: 16px;
    height: 100px;
    line-height: 24px;
}

</style>
