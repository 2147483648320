<template>

<div class="textbox">

    <input type="text" :placeholder="input.placeholder" />

</div>

</template>

<script>

export default {

    props: ['input']

}

</script>

<style scoped>

.textbox {
    border-radius: 8px;
    border: 1px solid #00573C;
    margin-bottom: 20px;
}

.is-mobile .textbox {
    margin-bottom: 10px;
}

.textbox input {
    height: 70px;
    padding: 0px 20px;
    width: 100%;
    color: #343434;
    font-size: 16px;
}

.is-mobile .textbox input {
    height: 56px;
}

.textbox input::placeholder {
    text-transform: uppercase;
    font-size: 14px;
    color: #00583D;
    letter-spacing: 1.4px;
}

</style>
