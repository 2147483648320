<template>

<div class="events" v-if="is.loading || list.length">

    <div class="inner">

        <div class="events-title">{{ item.title }}</div>

        <app-backend-icon class="events-loading" icon="loading" v-if="is.loading" />

        <div class="events-links" v-if="!is.loading">

            <a :href="link.url" class="events-links-item" v-for="(link, index) in list" :key="index">

                <div class="events-links-item-image" v-bgimage="link.image" />

                <div class="events-links-item-info">

                    <div class="events-links-item-date">{{ link.date | localDate('dddd DD MMMM') }}</div>

                    <div class="events-links-item-title">{{ link.name }}</div>

                    <div class="events-links-item-icons">

                        <div class="events-links-item-icons-item"><i class="fa-solid fa-clock" /> {{ link.date | localDate('H:mma') }}</div>

                        <div class="events-links-item-icons-item"><i class="fa-solid fa-location-dot" /> {{ link.location }}</div>

                    </div>

                </div>

            </a>

        </div>  

    </div>

</div>

</template>

<script>

export default {

    props: ['item'],

    data: function() {
    
        return {
            is: {
                loading: true
            },
            rawlist: []
        }

    },

    created: function() {
    
        this.load()
    
    },

    computed: {
    
        list: function() {
        
            var list = this.rawlist
            
            return list
        
        }

    },

    methods: {
    
        load: function() {
        
            this.$api.get('../frontend/events').then(function(response) {
            
                this.$vueset(this, 'rawlist', response.list)    

                this.is.loading = false        
            
            }.bind(this))
        
        }
    
    }

}

</script>

<style scoped>

.events {
    background-color: #FBF1E4;
    padding: 64px 10px;
}

.is-tablet .events {
    padding: 48px 10px;
}

.is-mobile .events {
    padding: 32px 10px;
}

.events-title {
    color: #00583D;
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 64px;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .events-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 48px;
}

.is-mobile .events-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 32px;
}

.events-loading {
    font-size: 32px;
    color: #00583D;
    margin: 0px auto;
    display: block;
    width: 32px;
}

.events-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.is-mobile .events-links {
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.events-links-item-image {
    background-position: 50% 50%;
    background-size: cover;
    height: 280px;
    border-bottom: 7px solid #4BA574;
}

.is-tablet .events-links-item-image {
    height: 226px;
    border-bottom-width: 5px;
}

.is-mobile .events-links-item-image {
    height: 160px;
    border-bottom-width: 5px;
}

.events-links-item-info {
    background-color: #fff;
    padding: 20px;
}

.events-links-item-date {
    color: #45464B;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.events-links-item-title {
    color: #00583D;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .events-links-item-title {
    font-size: 20px;
    line-height: 24px;
}

.is-mobile .events-links-item-title {
    font-size: 16px;
    line-height: 24px;
}

.events-links-item-icons {
    display: grid;
    margin-top: 40px;
    grid-template-columns: 1fr 1fr;
}

.is-mobile .events-links-item-icons {
    margin-top: 20px;
}

.events-links-item-icons-item {
    color: #45464B;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.events-links-item-icons-item >>> .fa-solid {
    color: #00583D!important;
    font-size: 16px;
    margin-right: 10px;
}

</style>
