<template>

<app-backend-input :label="label" :validation="validation">

    <div class="tags">

        <input class="tags-compose" :class="{'has-tags': tags.length}" type="text" v-model="compose" :placeholder="placeholder" v-on:keypress.enter="onEnterPress" />

        <div class="tags-list" v-if="tags.length">

            <div class="tags-list-item" :class="{'is-active': $_.contains(value, tag)}" v-for="(tag, index) in tags" :key="index" v-tooltip="($_.contains(value, tag)) ? 'Click to remove' : 'Click to add'" v-on:click="onTagClick(tag)">

                <div class="tags-list-item-text">{{ tag }}</div>

                <app-backend-icon icon="action.cancel" class="tags-list-item-button" v-if="$_.contains(value, tag)" />

            </div>

        </div>

    </div>

</app-backend-input>

</template>

<script>

export default {

	props: ['validation', 'label', 'value', 'placeholder', 'options'],

	data: function() {

		return {
			compose: ''
		}

	},

	computed: {

		tags: function() {

			var tags = []

			this.$_.each(this.value, function(value) {

				tags.push(value)

			})

			this.$_.each(this.options, function(value) {

				if(!this.$_.contains(this.value, value)) tags.push(value)

			}.bind(this))

			return tags

		}

	},

	methods: {

		onTagClick: function(tag) {

			var newValue = this.$_.clone(this.value)

			if(this.$_.contains(this.value, tag)) {
			
				newValue.splice(newValue.indexOf(tag), 1)
			
			} else {

				newValue.push(tag)
			
			}

			this.$emit('input', newValue)

		},

		onEnterPress: function() {

			if (this.compose) {

				var tag = this.compose.toLowerCase()

				if (!this.$_.contains(this.value, tag)) {

					var newValue = this.$_.clone(this.value)
					newValue.push(tag)

					this.$emit('input', newValue)
					
				}

				this.compose = ''

			}

		}

	}

}

</script>

<style scoped>

.tags-compose {
	height: 40px;
	border: 2px solid #eee;
	border-radius: 6px;
	padding: 0px 10px;
	color: #343434;
	margin-bottom: 0px;
	width: 100%;
	font-size: 16px;
}

.tags-compose::placeholder {
    color: #ccc;
}

.tags-compose.has-tags {
	border-radius: 4px 4px 0px 0px;
}

.tags-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: #eee;
	border-radius: 0px 0px 4px 4px;
	padding: 10px 10px 0px 0px;
}

.tags-list-item {
	height: 30px;
	line-height: 28px;
	border-radius: 4px;
	padding: 0px 10px;
    margin: 0px 0px 10px 10px;
	background-color: #ccc;
	color: #fff;
	font-size: 16px;
	display: flex;
	user-select: none;
}

.tags-list-item:not(.is-active) {
	cursor: pointer;
}

.tags-list-item:not(.is-active):hover {
	background-color: #999;
}

.tags-list-item.is-active {
	background-color: #28406d;
}

.tags-list-item-button {
	font-size: 16px;
	line-height: 32px;
	margin-left: 10px;
	cursor: pointer;
}

</style>
