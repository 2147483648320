<template>

<app-backend-input :label="label" :is-stacked="isStacked" :validation="validation">

    <div class="image" :class="{'has-preview': model.upload, 'is-skipping': skipAssets}">

        <div class="image-preview" v-bgimage="model.upload"><div class="image-placeholder" v-on:click="onUploadClick" v-if="!model.upload">{{ placeholder }}</div></div>

        <div class="image-actions">

            <app-backend-action icon="action.enlarge" tooltip="Enlarge image" v-on:click="onEnlargeClick" v-if="model.upload" />

            <app-backend-action icon="action.remove" tooltip="Remove image" v-on:click="onRemoveClick" v-if="model.upload" />

            <app-backend-action icon="action.upload" tooltip="Upload image" v-on:click="onUploadClick" />

            <app-backend-action icon="action.assets" tooltip="Select image" v-on:click="onAssetsClick" v-if="!skipAssets" />

        </div>

    </div>

    <template v-if="!skipAssets">

        <app-backend-input-toggle class="image-share" v-model="model.share" :options="shareOptions" v-if="is.uploading" />

        <app-backend-input-text class="image-name" v-model="model.name" placeholder="(optional) Give name to image" v-if="is.uploading && model.share " />

        <app-backend-input-tags class="image-tags" v-model="model.tags" placeholder="(optional) Add tags to image" v-if="is.uploading && model.share" />

    </template>

	<input ref="file" type="file" accept=".png,.jpg,.jpeg" v-on:change="onFileSelected" class="file" />

</app-backend-input>

</template>

<script>

export default {

    props: ['label', 'value', 'format', 'isStacked', 'skipAssets', 'validation', 'placeholder'],

    data: function() {
    
        return {
            is: {
                uploading: false
            },
            model: {
                share: 1,
                name: '',
                tags: [],
                upload: ''
            },
            shareOptions: {
                1: 'Add to library for re-use',
                0: 'Do not add to library'
            }
        }

    },
    
    created: function() {
    
        this.model.upload = this.value
    
    },

    watch: {

        value: function() {
        
            if (this.$_.isString(this.value)) {

                if(this.value !== this.model.upload) {
                
                    this.model.upload = this.value
                    this.is.uploading = false

                }
            
            
            }

        },
    
        model: {

            deep: true,

            handler: function() {
            
                if (this.is.uploading) {
                
                    this.$emit('input', (this.model.upload) ? this.$util.copy(this.model) : '')

                }

            }
        
        }

    },

    methods: {

        onEnlargeClick: function() {
        
            this.$store.dispatch('enlarge/request', this.model.upload)

        },

        onAssetsClick: function() {
        
            this.$store.dispatch('assets/request', {
                type: this.$constants.assetType.image
            }).then(function(response) {
            
                this.onRemoveClick()

				this.$emit('input', response.filename)
            
            }.bind(this))

        },
    
        onRemoveClick: function() {

            this.model.share = 1
            this.model.name = ''
            this.model.tags = []
            this.model.upload = ''

            this.is.uploading = false
				
		},

		onUploadClick: function() {

			this.$refs.file.click()
				
		},

        onFileSelected: function(e) {

			var file = e.target.files[0]

			var reader = new FileReader()

            reader.onloadend = function() {

                this.is.uploading = true

                this.model.share = 1
                this.model.name = ''
                this.model.tags = []
                this.model.upload = reader.result

				this.$emit('input', this.$util.copy(this.model))

			}.bind(this)

			reader.readAsDataURL(file)
			
		}
    
    }

}

</script>

<style scoped>

.image {
    display: grid;
    grid-template-columns: 1fr 85px;
    grid-gap: 5px;
}

.image.is-skipping {
    grid-template-columns: 1fr 40px;
}

.image-preview {
    padding: 0px 10px;
    font-size: 16px;
    color: #ccc;
    line-height: 36px;
    border: 2px solid #eee;
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
}

.image-placeholder {
    width: 100%;
}

.image.has-preview {
    grid-template-columns: 1fr 40px;
}

.image.has-preview .image-preview {
    display: flex;
    justify-content: flex-end;
    border-radius: 4px;
    background-color: #eee;
    height: 175px;
    border: 10px solid #eee;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.image-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px;
    grid-gap: 5px;
}

.image.is-skipping .image-actions {
    grid-template-columns: 1fr;
}

.image.has-preview .image-actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 40px 40px 40px;
}

.image-share {
    margin-top: 10px;
}

.file {
	display: none;
}

</style>
