<template>

<app-backend-input :label="label" :validation="validation">

    <div class="repeat-item" v-for="(item, index) in items" :key="index">

        <div class="repeat-item-head" :class="{'is-grouped': isGrouped}">

            <slot :name="'item' + index" v-if="!isGrouped"></slot>

            <app-backend-content-body-heading :text="title + ' #' + (index + 1)" v-if="isGrouped" />

            <app-backend-action icon="action.up" theme="grey" tooltip="Move up" :is-disabled="index === 0" v-on:click="onUpClick(index)" />

            <app-backend-action icon="action.down" theme="grey" tooltip="Move down" :is-disabled="index === items.length - 1" v-on:click="onDownClick(index)" />

            <app-backend-action icon="action.cancel" theme="red" tooltip="Remove" v-on:click="onRemoveClick(index)" />

        </div>

        <slot :name="'item' + index" v-if="isGrouped"></slot>

    </div>

    <app-backend-button :text="button || 'Add Item'" v-on:click="onAddClick" class="repeat-button" />

</app-backend-input>

</template>

<script>

export default {

    props: ['label', 'value', 'button', 'title', 'validation', 'isGrouped', 'template'],

    data: function() {
    
        return {
            items: []        
        }
    
    },

    created: function() {
    
        this.items = this.$util.copy(this.value)
    
    },

    watch: {
    
        value: {

            deep: true,
        
            handler: function(n) {
        
                this.items = this.$util.copy(n)
            
            }

        }

    },

    methods: {

        onAddClick: function() {
        
            this.items.push((this.$_.isObject(this.template)) ? this.$util.copy(this.template) : this.template)

            this.$emit('input', this.items)
        
        },

        onUpClick: function(index) {

            this.items.splice(index - 1, 0, this.items[index])

            this.items.splice(index + 1, 1)

            this.$emit('input', this.items)

        },

        onDownClick: function(index) {
            
            this.items.splice(index + 2, 0, this.items[index])

            this.items.splice(index, 1)

            this.$emit('input', this.items)

        },

        onRemoveClick: function(index) {
        
            this.items.splice(index, 1)

            this.$emit('input', this.items)
        
        }
    
    }

}

</script>

<style scoped>

.repeat-item {
    margin-bottom: 10px;
}

.repeat-item-head {
    display: grid;
    grid-template-columns: 1fr 40px 40px 40px;
    grid-gap: 5px;
    margin-bottom: 10px;
}

.repeat-item-head.is-grouped {
    margin-bottom: 10px;
}

.repeat-button {
    margin-top: 10px;
}

.flip-list-move {
    transition: transform 300ms;
}

</style>
