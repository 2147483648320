<template>

<div class="input" :class="{'is-first': isFirst}">

    <app-backend-icon class="input-insert is-before" tooltip="Add input" icon="action.insert" v-on:click="onInsertBeforeClick" v-if="isFirst" />

    <div class="input-image" />

    <div class="input-info">
    
        <div class="input-info-name">{{ item.label }}</div>

        <div class="input-info-type">{{ $constants.inputTypeName[item.type] }}</div>

    </div>

    <div class="input-actions">

        <app-backend-action class="input-actions-item" :is-disabled="isFirst" theme="grey" icon="action.up" tooltip="Move up" v-on:click="onUpClick" />
        
        <app-backend-action class="input-actions-item" :is-disabled="isLast" theme="grey" icon="action.down" tooltip="Move down" v-on:click="onDownClick" />
        
        <app-backend-action class="input-actions-item" icon="action.edit" tooltip="Edit input" v-on:click="onEditClick" />

    </div>

    <app-backend-icon class="input-insert is-after" tooltip="Add input" icon="action.insert" v-on:click="onInsertAfterClick" />

</div>

</template>

<script>

export default {

    props: ['item', 'index', 'isFirst', 'isLast'],

    methods: {
    
        onInsertBeforeClick: function() {

            this.$emit('insert', 0)
        
        },

        onInsertAfterClick: function() {
        
            this.$emit('insert', this.index + 1)
        
        },
        
        onUpClick: function() {
        
            this.$emit('move', {
                index: this.index,
                direction: 'up'
            })
        
        },

        onDownClick: function() {
        
            this.$emit('move', {
                index: this.index,
                direction: 'down'
            })

        },
        
        onEditClick: function() {
        
            this.$emit('edit', this.index)
        
        }
    
    }

}

</script>

<style scoped>

.input {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 80px 1fr 150px;
    align-items: center;
}

.input:hover {
    background-color: #f7f7f7;
}

.input.is-first {
    border-top: 1px solid #eee;
}

.input-image {
    border-radius: 4px;
    background-color: #eee;
    height: 64px;
}

.input-info-name {
    font-size: 16px;
    color: #343434;
}

.input-info-type {
    font-size: 12px;
    font-family: courier new;
    color: #999;
    margin-top: 4px;
}

.input-actions {
    display: flex;
    justify-content: flex-end;
}

.input-actions-item {
    margin-left: 5px;
    cursor: pointer;
}

.input-insert {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 38px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    font-size: 16px;
    border: 2px solid #eee;
    color: #343434;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    z-index: 2;
}

.input-insert.is-before {
    top: 0px;
}

.input-insert.is-after {
    top: 100%;
}

.input-insert:hover {
    background-color: #28406d;
    border-color: #28406d;
    color: #fff;
    cursor: pointer;
}

</style>
