<template>

<app-backend-input :label="label" :validation="validation">

    <div class="toggle" :data-count="$_.keys(options).length">

        <div class="toggle-item" :class="{'is-active': parseInt(key) === value}" v-for="(item, key) in options" :key="key" v-on:click="onClick(key)">{{ item }}</div>

    </div>

</app-backend-input>

</template>

<script>

export default {

    props: ['label', 'value', 'options', 'validation'],

    methods: {
    
        onClick: function(key) {
        
            this.$emit('input', parseInt(key))
        
        }
    
    }

}

</script>

<style scoped>

.toggle {
    display: grid;
    height: 40px;
}

.toggle[data-count="2"] {
    grid-template-columns: 1fr 1fr;
}

.toggle[data-count="3"] {
    grid-template-columns: 1fr 1fr 1fr;
}

.toggle-item {
    text-align: center;
    border: 2px solid #eee;
    border-left-width: 1px;
    border-right-width: 1px;
    font-size: 16px;
    color: #343434;
    user-select: none;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;
}

.toggle-item:first-child {
    border-radius: 4px 0px 0px 4px;
    border-left-width: 2px;
}

.toggle-item:last-child {
    border-radius: 0px 4px 4px 0px;
    border-right-width: 2px;
}

.toggle-item.is-active {
    border-color: #28406d;
    background-color: #28406d;
    color: #fff;
}

</style>
