<template>

<div class="input" :class="{'is-invalid': isInvalid, 'is-stacked': isStacked, 'has-label': label}" v-click-outside="onOutsideClick">

    <div class="input-label" v-if="label">{{ label }}</div>

    <div class="input-control">

        <slot></slot>

    </div>

</div>

</template>

<script>

export default {

    props: ['label', 'isStacked', 'validation'],

    computed: {
    
        isInvalid: function() {
        
			return (this.validation) ? this.validation.$error : false
        
        }
    
    },

    methods: {
    
        onOutsideClick: function() {
        
            this.$emit('blur')
        
        }
    
    }

}

</script>

<style scoped>

.input {
    margin-bottom: 10px;
}

.input:last-child {
    margin-bottom: 0px;
}

.input.has-label {
    display: grid;
    grid-template-columns: 120px 1fr;
}

.input.is-stacked.has-label {
    grid-template-columns: 1fr;
}

.input-label {
    line-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #343434;
}

.input.is-stacked .input-label {
    line-height: 16px;
    margin-bottom: 10px;
}

.input.is-invalid .input-label {
	color: #f25151;
}

</style>
