export default {

	namespaced: true,

	state: {
        active: false,
		resolve: false,
        reject: false
	},

	getters: {

		active: function(state) {

			return state.active

		}

	},

	mutations: {

        active: function(state, value) {
        
            state.active = value
        
        }

	},

    actions: {
    
        request: function(context, e) {

            context.dispatch('link/cancel', null, { root: true})
            context.dispatch('confirm/cancel', null, { root: true})
            context.dispatch('assets/cancel', null, { root: true})
        
            return new Promise(function(resolve, reject) {
            
                context.commit('active', e)

                context.state.resolve = resolve
                context.state.reject = reject
            
            })
        
        },
    
        resolve: function(context, e) {

            context.state.resolve(e)
            
            context.commit('active', false)
        
        },
    
        reject: function(context) {

            context.commit('active', false)
        
        },
    
        cancel: function(context) {

            context.commit('active', false)
        
        }
    
    }

}
