import axios from 'axios'
import _ from 'underscore'

const CancelToken = axios.CancelToken

export default {

	api: (process.env.VUE_APP_TYPE === 'backend') ? 'https://api.ecc.staging.exesios.com/backend/' : 'https://api.ecc.staging.exesios.com/frontend/',

	source: false,

	get: function(url, params, page) {

		params = params || {}
		page = page || false

		if (page) params['page'] = page

		this.source = CancelToken.source()

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'get', url, params)

			axios.get(this.api + url + '.json', {
				cancelToken: this.source.token,
				headers: {
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0',
				},
				params: params
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				if(json.response.status === 401) {

					window.location.href = window.location.href.split('/').slice(0, 3).join('/')
				
				} else {

					reject((json.response) ? json.response.data.data : null)
				
				}

			})

		}.bind(this))

	},

	post: function(url, params, query) {

		params = params || {}
		query = query || null

		if (_.isArray(url)) url = url.join('/')

		this.source = CancelToken.source()

		return new Promise(function(resolve, reject) {

			console.log('api', 'post', url, params, query)

			axios.post(this.api + url + '.json', params, {
				cancelToken: this.source.token,
				params: query
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)
				
			})

		}.bind(this))

	},

	patch: function(url, params, query) {

		params = params || {}
		query = query || null

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'patch', url, params, query)

			axios.patch(this.api + url + '.json', params, {
				params: query
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	put: function(url, params, query) {

		params = params || {}
		query = query || null

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'put', url, params, query)

			axios.put(this.api + url + '.json', params, {
				params: query
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	delete: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'delete', url, params)

			axios.delete(this.api + url + '.json', {
				data: params
			}).then(function(response) {

				if (_.isObject(response.data)) {

					this.response(resolve, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	response: function(resolve, response) {

		resolve(response.data.data)

	}

}