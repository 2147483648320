<template>

<div class="page">

    <slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.page {
    position: absolute;
    left: 64px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    width: 600px;
    overflow-y: auto;
    background-color: #fff;
    transition: left 100ms linear;
}

.is-fullscreen .page {
    left: -600px;
}

</style>
