<template>

<div class="buttons">

    <div class="buttons-inner inner">

        <div class="buttons-title">{{ item.title }}</div>

        <div class="buttons-links">

            <a class="buttons-links-item" :href="link.url" v-for="(link, index) in item.buttons" :key="index">{{ link.text }}</a>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.buttons {
    background-color: #D5E3D7;
}

.buttons-inner {
    padding: 64px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.is-mobile .buttons-inner {
    padding: 32px 10px 48px 10px;
}

.buttons-title {
    color: #00583D;
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;
    font-family: "Montserrat", sans-serif;
}

.is-tablet .buttons-title {
    font-size: 28px;
    line-height: 32px;
}

.is-mobile .buttons-title {
    font-size: 20px;
    line-height: 26px;
}

.buttons-links {
    display: grid;
    width: 100%;
    grid-gap: 20px;
    justify-content: center;
    margin-top: 32px;
    grid-template-columns: repeat(auto-fit, 188px);
}

.is-mobile .buttons-links {
    grid-gap: 15px;
    grid-template-columns: 188px;
}

.buttons-links-item {
    border: 1px solid #00573C;
    border-radius: 8px;
    text-transform: uppercase;
    color: #00573C;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    line-height: 70px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAABLCAMAAADXnC7SAAAAP1BMVEUAAAAAWD0AWDwAWT0AVzwAVz0AYEAAWD0AWD0AWjoAWD0AVz4AWD0AWT0AWEAAWD0AWT0AVz0AWD0AVz4AWD11/yeTAAAAFHRSTlMAwEA/l/AQgGAw4M+gcCDQUK+wb6KSSSIAAAFPSURBVEjH7ZbbjoMgEIbBylk88v7PuvgbpAii7SZ7sfG7aArzDQyDiZIScnLOLJzcQ2oHmLiT0cDeYMuVzSeIoufbLqapL85WaVBbXRiIyuLtVnY6pvxMNwj3JKL0uoA615k8TM5IONOHPGLLCf16uK5UqkRCafWOgDsJXarnCYanRUKvJUwk0kCvsF6EjcVr3xlOagz+CPvFCAxqoH00/Pebje+xiYJJHU4o997Mx14BSt6gzumteh9RSaToq7CBRSD1hQfThQ00qk8DxHP0R9SB05IrHzC/L9LoPX/GTIusuh+vCVF55cenBtHmE59d+RH47ktfvQIm+Oa1c/RHl4Bogk39LvdZMkNTnybBFu2u+vg95T/6EW3DayxCL/sPPfq/u1/Cm50h+EOc458+n4//+I//+I//9/7yArXvARD8HPgZX/mizRGrb9sC5AfRP0w/lq0OrwAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 20px 50%;
    background-size: auto 41px;
}

.is-tablet .buttons-links-item {
    font-size: 14px;
}

.is-mobile .buttons-links-item {
    background-size: 18px auto;
    font-size: 13px;
    line-height: 54px;
}

.buttons-links-item:hover {
    color: #fff;
    background-color: #00573C;
}

</style>
