<template>

<div id="app" class="screen" :class="{'is-loggedin': $loggedin, 'is-fullscreen': $store.getters['preview/fullscreen']}">

    <template v-if="!is.loading">

        <router-view :key="$path" v-if="!$loggedin" />

        <template v-if="$loggedin">
        
            <com-page>

                <router-view :key="$path" />

            </com-page>

            <com-sidebar />
            <com-preview />
            <com-confirm />
            <com-assets />
            <com-enlarge />
            <com-link />

        </template>

    </template>

</div>

</template>

<script>

import comSidebar from './backend/Sidebar'
import comPage from './backend/Page'
import comPreview from './backend/Preview'
import comConfirm from './backend/Confirm'
import comAssets from './backend/Assets'
import comEnlarge from './backend/Enlarge'
import comLink from './backend/Link'

export default {

    name: 'Backend',

    components: {
        'com-sidebar': comSidebar,
        'com-page': comPage,
        'com-preview': comPreview,
        'com-confirm': comConfirm,
        'com-assets': comAssets,
        'com-enlarge': comEnlarge,
        'com-link': comLink
    },

    data: function() {
    
        return {
            is: {
                loading: true
            }
        }

    },

    created: function() {
    
        this.$store.dispatch('init').then(function() {
        
            this.is.loading = false
        
        }.bind(this))
    
    }

}

</script>

<style scoped>

.screen {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #5480d3;
    height: 100%;
    background-image: linear-gradient(0deg, transparent 9%, hsla(0, 0%, 100%, .2) 10%, hsla(0, 0%, 100%, .2) 12%, transparent 13%, transparent 29%, hsla(0, 0%, 100%, .1) 30%, hsla(0, 0%, 100%, .1) 31%, transparent 32%, transparent 49%, hsla(0, 0%, 100%, .1) 50%, hsla(0, 0%, 100%, .1) 51%, transparent 52%, transparent 69%, hsla(0, 0%, 100%, .1) 70%, hsla(0, 0%, 100%, .1) 71%, transparent 72%, transparent 89%, hsla(0, 0%, 100%, .1) 90%, hsla(0, 0%, 100%, .1) 91%, transparent 92%, transparent), linear-gradient(90deg, transparent 9%, hsla(0, 0%, 100%, .2) 10%, hsla(0, 0%, 100%, .2) 12%, transparent 13%, transparent 29%, hsla(0, 0%, 100%, .1) 30%, hsla(0, 0%, 100%, .1) 31%, transparent 32%, transparent 49%, hsla(0, 0%, 100%, .1) 50%, hsla(0, 0%, 100%, .1) 51%, transparent 52%, transparent 69%, hsla(0, 0%, 100%, .1) 70%, hsla(0, 0%, 100%, .1) 71%, transparent 72%, transparent 89%, hsla(0, 0%, 100%, .1) 90%, hsla(0, 0%, 100%, .1) 91%, transparent 92%, transparent);
    background-size: 50px 50px;
}

</style>
