<template>

<app-backend-input :label="label" :validation="validation">

    <date-picker v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="placeholder" :type="(hasTime) ? 'datetime' : 'date'" :format="(hasTime) ? 'h:mma DD/MM/YYYY' : 'DD/MM/YYYY'" valueType="timestamp" class="date" />

</app-backend-input>

</template>

<script>

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {

    props: ['label', 'value', 'validation', 'placeholder', 'hasTime'],

    components: { DatePicker }

}

</script>

<style scoped>

.date {
    display: flex;
    border: 2px solid #eee;
    border-radius: 4px;
    height: 40px;
    width: 100%;
}

.date >>> .mx-input {
    width: 100%;
    border: 0px;
    height: 36px;
    padding: 0px 10px;
    font-size: 16px;
    color: #343434;
    border-radius: 0px;
    background-color: transparent;
    line-height: 36px;
    box-shadow: none;
}

.date >>> .mx-input::placeholder {
    color: #ccc;
}

.date >>> .mx-icon-calendar {
    display: none;
}

.date >>> .mx-input-wrapper {
    width: 100%;
}

</style>
