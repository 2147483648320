<template>

<div class="select">

    <div class="select-value">{{ input.placeholder }} <i class="fa-solid fa-chevron-down" /></div>

</div>

</template>

<script>

export default {

    props: ['input']

}

</script>

<style scoped>

.select {
    border-radius: 8px;
    border: 1px solid #00573C;
    margin-bottom: 20px;
}

.is-mobile .select {
    margin-bottom: 10px;
}

.select-value {
    height: 70px;
    padding: 0px 20px;
    line-height: 70px;
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    color: #00583D;
    letter-spacing: 1.4px;
}

.is-mobile .select-value {
    height: 56px;
    line-height: 56px;
}

.select-value i {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -6px;
}

</style>
