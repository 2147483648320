import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import api from './api'
import cookies from 'js-cookie'

import modPreview from './store/preview'
import modConfirm from './store/confirm'
import modAssets from './store/assets'
import modEnlarge from './store/enlarge'
import modLink from './store/link'

Vue.use(Vuex)

var store = new Vuex.Store({

	modules: {
		preview: modPreview,
		confirm: modConfirm,
		assets: modAssets,
		enlarge: modEnlarge,
		link: modLink
	},

	state: {
		initiated: false,
		loggedin: false,
		key: ''
	},

	getters: {

		initiated: function(state) {

			return state.initiated

		},

		key: function(state) {

			return state.key

		},

		loggedin: function(state) {

			return state.loggedin

		}

	},

	mutations: {

		initiated: function(state, value) {

			state.initiated = value

		},

		loggedin: function(state, value) {

			state.loggedin = value

		},

		key: function(state, value) {

			state.key = value

			if (value) {
				
				cookies.set('cookie', value, {
					expires: 365
				})

			} else {

				cookies.remove('cookie')

			}

			axios.defaults.headers.common['Authorization'] = value

		}

	},

	actions: {

		init: async function(context) {

			return new Promise(function(resolve) {
			
				if (cookies.get('cookie') !== undefined) {
				
					context.commit('key', cookies.get('cookie'))

					api.get('session').then(function() {

						context.commit('loggedin', true)

						resolve()
					
					}, function() {

						context.commit('key', undefined)

						resolve()
					
					})

				} else {
				
					resolve()
				
				}
			
			})

		}

	}

})

export default store