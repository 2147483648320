<template>

<div class="gallery inner">

    <div class="gallery-item" v-for="(image, index) in item.images" :key="index" v-bgimage="image.image" />

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.is-tablet .gallery {
    grid-gap: 10px;
}

.is-mobile .gallery {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

.gallery-item {
    background-position: 50% 50%;
    background-size: cover;
    height: 265px;
}

.is-tablet .gallery-item {
    height: 150px;
}

.is-movile .gallery-item {
    height: 150px;
}

.gallery-item:nth-child(1) { grid-column: span 1; }
.gallery-item:nth-child(2) { grid-column: span 2; }
.gallery-item:nth-child(3) { grid-column: span 2; }
.gallery-item:nth-child(4) { grid-column: span 2; }

.gallery-item:nth-child(5) { grid-column: span 2; }
.gallery-item:nth-child(6) { grid-column: span 1; }
.gallery-item:nth-child(7) { grid-column: span 1; }
.gallery-item:nth-child(8) { grid-column: span 1; }
.gallery-item:nth-child(9) { grid-column: span 2; }

.is-mobile .gallery-item:nth-child(1) { grid-column: span 2; }
.is-mobile .gallery-item:nth-child(2) { grid-column: span 4; }
.is-mobile .gallery-item:nth-child(3) { grid-column: span 4; }
.is-mobile .gallery-item:nth-child(4) { grid-column: span 2; }
.is-mobile .gallery-item:nth-child(5) { grid-column: span 3; }
.is-mobile .gallery-item:nth-child(6) { grid-column: span 3; }
.is-mobile .gallery-item:nth-child(7) { grid-column: span 2; }
.is-mobile .gallery-item:nth-child(8) { grid-column: span 2; }
.is-mobile .gallery-item:nth-child(9) { grid-column: span 2; }

</style>
