<template>

<div class="divider-2" v-bgimage="item.image">

    <div class="divider-2-inner inner">

        <div class="divider-2-text">{{ item.text }}</div>

        <div class="divider-2-subtitle">{{ item.subtitle }}</div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.divider-2 {
    background-position: 50% 50%;
    background-size: cover;
}

.divider-2-inner {
    padding: 160px 0px 100px 0px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.is-tablet .divider-2-inner {
    padding: 100px 0px 60px 0px;
}

.is-mobile .divider-2-inner {
    padding: 40px 0px 30px 0px;
}

.divider-2-text {
    font-size: 28px;
    line-height: 38px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    max-width: 880px;
}

.is-tablet .divider-2-text {
    font-size: 24px;
    line-height: 34px;
}

.is-mobile .divider-2-text {
    font-size: 16px;
    line-height: 26px;
    padding: 0px 30px;
}

.divider-2-subtitle {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-top: 64px;
}

.is-tablet .divider-2-subtitle {
    font-size: 14px;
    margin-top: 40px;
}

.is-mobile .divider-2-subtitle {
    font-size: 13px;
    margin-top: 20px;
    padding: 0px 30px;
}

</style>
