<template>

<component :is="component" :to="route" class="page" :data-depth="(isNested) ? item.depth : 0" :class="{'has-image': showImage, 'is-last': item.last, 'is-nested': isNested, 'has-date': showDate, 'is-offline':item.status === $constants.pageStatus.offline}" v-tooltip="(isNested) ? false : tooltip" v-on:click="onClick">

    <div class="page-image" v-bgimage="item.image" v-if="showImage" />

    <div class="page-info" v-on:click="$emit('click')" v-tooltip="(isNested) ? 'Click to edit' : false">
    
        <div class="page-info-name">{{ item.name }}</div>

        <div class="page-info-url" v-if="!showTags && !showLocation && !isNested">{{ item.url }}</div>

        <div class="page-info-url" v-if="showLocation">{{ item.location }}</div>

        <div class="page-info-tags" v-if="showTags">
        
            <div class="page-info-tags-item" v-for="(tag, index) in item.tags" :key="index">{{ tag }}</div>
        
        </div>

    </div>

    <div class="page-date" v-if="showDate">

        {{ item.date | localDate('DD/MM/YYYY') }}

    </div>

    <div class="page-actions" v-if="isNested">

        <slot></slot>

    </div>

    <div class="page-icon" v-if="!isNested">

        <i class="fa-solid fa-chevron-right" v-if="!isSelectable" />

    </div>

    <div class="page-indent" v-if="isNested && item.depth" />

</component>

</template>

<script>

export default {

    props: ['item', 'showTags', 'showImage', 'showDate', 'showLocation', 'isNested', 'isSelectable'],

    computed: {

        tooltip: function() {
        
            return (this.isSelectable) ? 'Click to select' : 'Click to edit'
        
        },

        component: function() {
        
            return (this.isNested || this.isSelectable) ? 'div' : 'router-link'

        },
    
        route: function() {
        
            return {
                name: this.$route.meta.editName,
                params: {
                    id: this.item.id
                }
            }
        
        }
    
    },

    methods: {
    
        onClick: function() {
        
            if (this.isSelectable) {
            
                this.$emit('click')
            
            } else if(!this.isNested) {
            
                //
            
            }
        
        }
    
    }

}

</script>

<style scoped>

.page {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    display: grid;
    background-color: #fff;
    grid-template-columns: 1fr 20px;
    grid-gap: 20px;
    align-items: center;
    border-left: 5px solid #43ac43;
    z-index: 20;
}

.page:not(.is-nested) {
    cursor: pointer;
}

.page[data-depth="1"] {
    margin-left: 64px;
    z-index: 15;
}

.page[data-depth="2"] {
    margin-left: 128px;
    z-index: 10;
}

.page[data-depth="3"] {
    margin-left: 194px;
    z-index: 5;
}

.page[data-depth="4"] {
    margin-left: 256px;
    z-index: 1;
}

.page.is-offline {
    border-left: 5px solid #aa3737;
}

.page.has-image {
    grid-template-columns: 48px 1fr 20px;
}

.page.has-date {
    grid-template-columns: 1fr 120px 20px;
}

.page.has-image.has-date {
    grid-template-columns: 48px 1fr 120px 20px;
}

.page.is-nested {
    grid-template-columns: auto fit-content(1px);
    grid-gap: 10px;
}

.page:hover {
    background-color: #f7f7f7;
}

.page-image {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-position: 50% 50%;
    background-size: cover;
    background-color: #eee;
}

.page.is-nested .page-info {
    cursor: pointer;
}

.page-info-name {
    font-size: 16px;
    color: #343434;
}

.page-info-url {
    font-size: 12px;
    font-family: courier new;
    color: #999;
    margin-top: 4px;
}

.page-info-tags {
    display: flex;
    margin-top: 4px;
}

.page-info-tags-item {
    border-radius: 4px;
	background-color: #28406d;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    margin-right: 4px;
}

.page-icon {
    font-size: 16px;
    text-align: right;
    color: #666;
}

.page-indent {
    position: absolute;
    left: -37px;
    width: 32px;
    top: calc(50% + 1px);
    border-bottom: 1px dashed #ccc;
    z-index: 1;
}

.page-indent:before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    bottom: 50%;
    height: 64px;
    border-left: 1px dashed #ccc;
}

.page-actions {
    display: flex;
    justify-content: flex-end;
}

.page-actions >>> .action {
    margin-left: 5px;
}

</style>
