<template>

<app-backend-input :label="label" :validation="validation">

    <div class="content" ref="editor" :class="{'no-toolbar': noToolbar}">

        <div class="content-toolbar" v-if="!noToolbar">

            <app-backend-icon class="content-toolbar-item" tooltip="Header 1" icon="toolbar.h1" v-on:click="onHeaderClick(1)" />

            <app-backend-icon class="content-toolbar-item" tooltip="Header 2" icon="toolbar.h2" v-on:click="onHeaderClick(2)" />

            <app-backend-icon class="content-toolbar-item" tooltip="Header 3" icon="toolbar.h3" v-on:click="onHeaderClick(3)" />

            <app-backend-icon class="content-toolbar-item" tooltip="Paragraph" icon="toolbar.p" v-on:click="onParagraphClick" />

            <app-backend-icon class="content-toolbar-item" tooltip="Bold" icon="toolbar.bold" v-on:click="onBoldClick" />
            
            <app-backend-icon class="content-toolbar-item" tooltip="Italic" icon="toolbar.italic" v-on:click="onItalicClick" />
            
            <app-backend-icon class="content-toolbar-item" tooltip="Underline" icon="toolbar.underline" v-on:click="onUnderlineClick" />
            
            <app-backend-icon class="content-toolbar-item" tooltip="Unordered List" icon="toolbar.ulist" v-on:click="onUlClick" />
            
            <app-backend-icon class="content-toolbar-item" tooltip="Ordered List" icon="toolbar.olist" v-on:click="onOlClick" />
            
            <app-backend-icon class="content-toolbar-item" tooltip="Internal Link" icon="toolbar.internal" v-on:click="onInternalClick" />
            
            <app-backend-icon class="content-toolbar-item" tooltip="External Link" icon="toolbar.external" v-on:click="onExternalClick" />

        </div>

        <com-editor class="content-editor" :editor="editor" />

    </div>

</app-backend-input>

</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'

export default {

    props: ['label', 'value', 'validation', 'noToolbar', 'placeholder'],

    components: {
        'com-editor': EditorContent,
    },

    data: function() {
    
        return {
            editor: null
        }
    
    },

    created: function() {
    
        this.editor = new Editor({
            content: this.value,
            autofocus: true,
            extensions: [
                StarterKit.configure({
                    heading: {
                        levels: [1, 2, 3],
                    }
                }),
                Underline,
                Link.configure({
                    openOnClick: false,
                    defaultProtocol: 'https'
                })
            ],
            onCreate: function() {

				this.$refs.editor.addEventListener('click', this.onEditorClick.bind(this))

			}.bind(this),
			onDestory: function() {

				this.$refs.editor.removeEventListener('click', this.onEditorClick.bind(this))

			}.bind(this),
            onUpdate: function() {
            
                this.$emit('input', this.editor.getHTML())
            
            }.bind(this)
        })
    
    },

    beforeDestroy: function() {
    
        this.editor.destroy()
    
    },

    watch: {
    
        value: function(n) {
        
            const isSame = this.editor.getHTML() === n

            if (isSame) return 

            this.editor.commands.setContent(n, false)
        
        }
    
    },

    methods: {
    
        onEditorClick: function(e) {

			e.stopPropagation()

			if (e.target.tagName === 'A') {

                this.editor.chain().focus().extendMarkRange('link').run()

                if(e.target.target === '_blank') {
                
                    this.onExternalClick()
                
                } else {
                
                    this.onInternalClick()
                
                }

			}

		},

        onHeaderClick: function(level) {
        
            this.editor.chain().focus().toggleHeading({ level: level }).run()

        },

        onParagraphClick: function() {
        
            this.editor.commands.setParagraph()            

        },
    
        onBoldClick: function() {
        
            this.editor.chain().focus().toggleBold().run()
        
        },
    
        onItalicClick: function() {
        
            this.editor.chain().focus().toggleItalic().run()
        
        },
    
        onUnderlineClick: function() {
        
            this.editor.chain().focus().toggleUnderline().run()
        
        },
    
        onBlockquoteClick: function() {
        
            this.editor.chain().focus().toggleBlockquote().run()
        
        },
    
        onUlClick: function() {
        
            this.editor.chain().focus().toggleBulletList().run()
        
        },
    
        onOlClick: function() {
        
            this.editor.chain().focus().toggleOrderedList().run()
        
        },

        onInternalClick: function() {

            this.$store.dispatch('link/request', {
                type: 'internal'
            }).then(function(e) {

                console.log(e)
            
                this.editor.chain().focus().extendMarkRange('link').setLink({ href: e.url, target: '_self' }).run()
            
            }.bind(this))
        
        },

        onExternalClick: function() {

            var existingLink = this.editor.getAttributes('link')

			if(existingLink.href) this.editor.chain().focus().extendMarkRange('link').run()
        
            this.$store.dispatch('link/request', {
                type: 'external',
                href: (existingLink.href) ? existingLink.href : '',
                text: this.editor.state.doc.textBetween(this.editor.state.selection.from, this.editor.state.selection.to, ' ')
            }).then(function(e) {

                this.editor.chain().focus().extendMarkRange('link').setLink({ href: e.url, target: '_blank' }).command(function(tr){

                    tr.commands.insertContent(e.text)

                    return true

                }.bind(this)).run()
            
            }.bind(this))

        }
    
    }

}

</script>

<style scoped>

.content-toolbar {
    border-radius: 4px 4px 0px 0px;
    background-color: #eee;
    padding: 10px;
}

.content-toolbar-item {
    height: 32px;
    width: 32px;
    font-size: 16px;
    color: #343434;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
}

.content-toolbar-item:hover {
    background-color: #ccc;
}

.content-editor {
    border: 2px solid #eee;
    border-top: 0px;
    padding: 20px;
    border-radius: 0px 0px 4px 4px;
}

.content.no-toolbar .content-editor {
    border: 2px solid #eee;
    border-radius: 4px;
    padding: 10px;
}

.content-editor >>> .tiptap h1 {
    font-size: 32px;
    line-height: 40px;
    color: #343434;
    margin-bottom: 20px;
}

.content-editor >>> .tiptap h2 {
    font-size: 26px;
    line-height: 34px;
    color: #343434;
    margin-bottom: 20px;
}

.content-editor >>> .tiptap h3 {
    font-size: 20px;
    line-height: 28px;
    color: #343434;
    margin-bottom: 20px;
}

.content-editor >>> .tiptap p {
    font-size: 16px;
    line-height: 20px;
    color: #343434;
    margin-bottom: 20px;
}

.content-editor >>> .tiptap i,
.content-editor >>> .tiptap em {
    font-style: italic;
}

.content-editor >>> .tiptap a {
    font-weight: 500;
    color: #28406d;
    border-bottom: 1px dashed #28406d;
    cursor: pointer;
}

.content-editor >>> .tiptap ul {
    list-style-type: disc;
}

.content-editor >>> .tiptap ol {
    list-style-type: decimal;
}

.content-editor >>> .tiptap ol,
.content-editor >>> .tiptap ul {
    list-style-position: outside;
    margin-bottom: 20px;
}

.content-editor >>> .tiptap li {
    font-size: 16px;
    line-height: 20px;
    color: #343434;
    margin-left: 20px;
}

.content-editor >>> .tiptap *:last-child {
    margin-bottom: 0px;
}

</style>
