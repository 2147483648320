<template>

<div id="app" class="screen" :class="{'is-desktop': window.is.desktop, 'is-tablet': window.is.tablet, 'is-mobile': window.is.mobile}">

    <template v-if="is.initialised">

    <app-frontend-block :item="{ type: $constants.blockType.head, site: site }" key="head" />

    <app-frontend-block :ref="block.id" :item="block" v-for="block in page.blocks" :key="block.id" />

    <app-frontend-block :item="{ type: $constants.blockType.foot, site: site  }" key="foot" />

    </template>
    
</div>

</template>

<script>

export default {

    name: 'Frontend',

    data: function() {
    
        return {
            is: {
                initialised: false
            },
            site: {},
            page: {}
        }
    
    },

    created: function() {
    
        this.load()
    
    },

    watch: {
    
        $path: function() {
        
            this.load()
        
        }
    
    },

    methods: {
    
        load: function() {
        
            this.$api.get('page', {
                path: this.$path
            }).then(function(response) {
            
                this.$vueset(this, 'site', response.site)
                this.$vueset(this, 'page', response.page)

                this.is.initialised = true
            
            }.bind(this), function() {
            
                //
            
            })
        
        }
    
    }

}

</script>

<style scoped>

.screen {
    
}

</style>
