<template>

<component :is="component" :to="routeFinal" class="action" v-tooltip="title" :data-theme="theme" :class="{'is-disabled': isDisabled, 'is-loading': isLoading}" v-on:click="onClick">

    <app-backend-icon :icon="iconFinal" class="action-icon" />

</component>

</template>

<script>

export default {

    props: ['route', 'title', 'icon', 'routeId', 'theme', 'isDisabled', 'isLoading'],

    computed: {

        component: function() {
        
            return (this.route) ? 'router-link' : 'div'

        },

        iconFinal: function() {
        
            return (this.isLoading) ? 'loading' : this.icon

        },

        routeFinal: function() {
        
            var route = {
                name: this.route,
                params: {}
            }

            if (this.routeId) {
            
                route.params.id = this.routeId
            
            }

            return route
        
        }
    
    },

    methods: {

        onClick: function() {
        
            if(!this.route && !this.isDisabled && !this.isLoading) {
            
                this.$emit('click')
            
            }
        
        }
    
    }

}

</script>

<style scoped>

.action {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    background-color: #28406d;
    font-size: 20px;
    cursor: pointer;
    margin-left: 5px;
}

.action:hover {
    background-color: #1E3052;
}

.action[data-theme="grey"] {
    
}

.action[data-theme="red"] {
    background-color: #aa3737;
}

.action[data-theme="red"]:hover {
    background-color: #8d2424;
}

.action[data-theme="green"] {
    background-color: #43ac43;
}

.action.is-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.action.is-loading {
    pointer-events: none;
}

.action-icon {
    color: #fff;
}

</style>
