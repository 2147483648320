<template>

<div class="embed inner" v-html="item.code" />

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

</style>
